import React from 'react';
import 'styled-components/macro';

import { connect } from 'react-redux';
import verikaiLogo from '../assets/VerikaiLogoColor.png';
// import verikaiLogo from "../assets/logo_login.svg";
import logo from "../assets/logos/logo.svg";
import {palette} from '../theme';

const mapStateToProps = state => ({
  prepopulate: state.prepopulate,
  referrerLogo: state.referrerLogo,
});

class Display extends React.Component
{
  render()
  {
    return <DisplayRoot
      hasLogo={true}
    >
      <DisplayMain
        wide={this.props.wide}
        signedUp={this.props.signedUp}
      >

        {/* Title */}
        <div
          className='displayHeader'
          css={`
            font-family: Mulish;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0em;
            color: ${palette.secondary.dark};
            margin-bottom: 26px;

            ${this.props.signedUp ? `background-color: ${palette.secondary.light}` : ""}
            ${this.props.signedUp ? `margin-bottom: 0px;` : ""}
            ${this.props.signedUp ? `padding: 30px 0px 0px 30px;` : ""}
            @media (max-width: 850px) {
              display: none;
            }
          `}
        >
          <img
            css={`
              height: 35px;
            `}
            src={process.env.REACT_APP_WHITE_LABEL == 'true' ? logo : verikaiLogo}
            alt="Verikai"
          />
          {/* Verikai sign up */}
        </div>

        {/* Outer Top */}
        {this.props.outerTop}

        {/* Inner Display */}
        {
          this.props.children
            ? <DisplayContainer
              noPaddingBottom={this.props.noPaddingBottom}
            >

              {/* Title */}
              <div
                css={`
                  font-family: Mulish;
                  font-size: 32px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 42px;
                  letter-spacing: 0em;
                  color: ${palette.secondary.dark};
                  margin-bottom: 32px;
                  @media (max-width: 850px) {
                    text-align: center;
                  }
                `}
              >
                {this.props.title}
              </div>

              {this.props.children}
            </DisplayContainer>
            : null
        }
        {this.props.outerBottom}
      </DisplayMain>
    </DisplayRoot>;
  }
}

export default Display;

class DisplayRoot extends React.Component
{
  render()
  {
    return <div
      className='displayRoot'
      css={`
        background-color: ${palette.secondary.sat};
        width: 100vw;
        height: 100vh;
        overflow-y: auto;

        @media (max-width: 850px) {
          background-color: ${palette.secondary.light50};
        }
      `}
    >
       {/* {
          this.props.hasLogo
            ? <DisplayLogo/>
            : null
        } */}
      <div
        css={`
          padding-top: 58.41px;
          @media (min-width: 0px) {
            padding-left: 170px;
            padding-right: 170px;
          }
          @media (max-width: 1280px) {
            padding-left: 150px;
            padding-right: 150px;
          }
          @media (max-width: 1056px) {
            padding-left: 75px;
            padding-right: 75px;
          }
          @media (max-width: 920px) {
            padding-left: 25px;
            padding-right: 25px;
          }
          padding-bottom: 15px;
          @media (max-width: 850px) {
            padding: 0px;
          }
        `}
      >
       
        {this.props.children}
      </div>
    </div>;
  }
}

class DisplayContainer extends React.Component
{
  render()
  {
    return <div
      className='displayContainer'
      css={`
        background-color: ${palette.secondary.light50};
        width: 100%;
        border-radius: 5px;
        padding-top: 40px;
        padding-left: 30px;
        padding-right: 30px;
        ${
          this.props.noPaddingBottom
            ? ''
            : 'padding-bottom: 60px;'
        }
        border: 1px solid ${palette.secondary.sat17};
        @media (max-width: 850px) {
          border-radius: 0px;
          border: none !important;
        }
      `}
    >
      {this.props.children}
    </div>;
  }
}

class DisplayLogoDisconnected extends React.Component
{
  render()
  {
    return <div
      css={`
        background-color: #fff;
        height: 54px;
        @media (min-width: 0px) {
          padding-left: 128px;
        }
        @media (max-width: 1280px) {
          padding-left: 50px;
        }
        @media (max-width: 1056px) {
          padding-left: 25px;
        }
        @media (max-width: 920px) {
          padding-left: 15px;
        }
        display: flex;
          align-items: center;
          justify-content: flex-start;
      `}
    >
      <img
        css={`
          height: 26.68px;
        `}
        src={process.env.REACT_APP_WHITE_LABEL == 'true' ? logo : verikaiLogo}
        alt="Verikai"
      />
      {
            this.props.referrerLogo
              ? <>
                <span
                  css={`
                    background-color: ${palette.secondary.dark};
                    margin-left: 23.35px;
                    margin-right: 23.35px;
                    width: 1px;
                    height: 26.68px;
                  `}
                />
                <img
                  css={`
                    max-height: 26.68px;
                    width: auto;
                    height: auto;
                  `}
                  src={this.props.referrerLogo}
                  alt="Customer"
                />
              </>
            : null
          }
    </div>;
  }
}

const DisplayLogo = connect(mapStateToProps)(DisplayLogoDisconnected);

class DisplayMain extends React.Component
{
  render()
  {
    return <div
    className='mainDisplayContainer'
      css={`
        display: flex;
        justify-content: center;
      `}
    >

      {/* Main Display */}
      <div
      className='mainDisplay'
        css={`
          width: ${
            this.props.wide
              ? '1074px'
              : '797px'
          };
          background-color: ${this.props.signedUp ? palette.secondary.light : palette.secondary.light25};
          border-radius: 10px;
          padding-left: ${
            this.props.wide
              ? '23px'
              : '45px'
          };
          padding-top: 16px;
          padding-right: ${
            this.props.wide
              ? '23px'
              : '45px'
          };
          padding-bottom: 17px;
          ${
            this.props.cssProps
              ? this.props.cssProps
              : ''
          }
          ${this.props.signedUp ? "padding: 0px;" : ""}
          @media (max-width: 850px) {
            padding: 0; 
            background-color: ${palette.secondary.light50};
          }
        `}
      >
        {this.props.children}
      </div>
    </div>;
  }
}

export {
  DisplayContainer,
  DisplayRoot,
  DisplayLogo,
  DisplayMain,
};
