// Extends MUI's <TextField/> with Verikai branding

import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import 'styled-components/macro'

import { palette } from '../../theme'

export default class VerikaiTextField extends Component {
  render() {

    const {
      ...other
    } = this.props

    return (
      <TextField
        {...other}
        css={`
          cursor: pointer;
          & input {
            background-color: #FFFFFF;
          }
          & input.Mui-disabled {
            color: ${palette.secondary.dark60};
            background-color: ${palette.secondary.sat17};
          }
          & .MuiOutlinedInput-root {
            .MuiOutlinedInput-notchedOutline {
              border: 1px solid ${palette.secondary.sat30};
            }
            &:hover .MuiOutlinedInput-notchedOutline {
              border: 2px solid ${palette.secondary.sat30};
            }
          }
          & .MuiFormHelperText-root {
            position: absolute;
            bottom: -21px;
          }
        `}
      />
    )
  }
}
