import React from 'react';
import 'styled-components/macro';

import Button from '@material-ui/core/Button';
import {
  DisplayRoot,
  DisplayMain,
} from './Display';

import DoneSvg from '../assets/Done.svg';

import { connect } from 'react-redux';
import { palette } from '../theme';
import resetUser from '../redux/actions/resetUser';
import { withRouter } from "react-router-dom";

const mapDispatchToProps = dispatch => ({
  resetUser: () => dispatch(resetUser()),
});

const mapStateToProps = state => ({
  user: state.user,
});

class Done extends React.Component
{
  componentDidMount()
  {
    const a = this.props.user.account;
    const b = this.props.user.businessInformation;
    if(!this.props.user.email || !this.props.user.emailVerified)
    {
      this.props.history.push('/create-account');
    }
    else if(!this.props.user.accountType)
    {
      this.props.history.push('/account-type');
    }
    else if
    (
      (
        this.props.user.accountType === 'Carrier'
        &&
        (
          !b.businessName
          || !b.joinMarketplace
        )
      )
      ||
      (
        this.props.user.accountType === 'Broker'
        && !b.brokerageName
      )
    )
    {
      this.props.history.push('/business-information');
    }
    else if
    (
      this.props.user.accountType === 'Broker'
      &&
      (
        !a.firstName
        || !a.lastName
        || !a.phoneNumber
        || !a.contactEmail
      )
    )
    {
      this.props.history.push('/person-to-contact-enterprise');
    }
    else
    {
      this.props.resetUser();
    }
  }

  render()
  {
    return <DisplayRoot
      hasLogo
    >
      <DisplayMain
        cssProps={`
          background-image: url(${DoneSvg});
          min-height: 434px;
          background-repeat: no-repeat;
          padding-top: 153px;
        `}
      >
        <div
          css={`
            font-family: Mulish;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 42px;
            letter-spacing: 0em;
            text-align: center;
            color: ${palette.secondary.dark};
            margin-bottom: 16px;
          `}
        >
          We're excited to get you setup!
        </div>
        <div
          css={`
            font-family: Mulish;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            color: ${palette.secondary.dark};
            margin-bottom: 108px;
          `}
        >
          A member of our Sales team will reach out to you with next steps. If you have any questions, please contact sales@verikai.com
        </div>
        <div
          css={`
            margin-bottom: 32px;
            display: flex;
            justify-content: flex-end;
            text-align: right;
          `}
        >
          <Button
            css={`
              background-color: ${palette.primary.main};
              box-shadow: none;
              margin-top: 7px;
              text-transform: none;
              font-family: Mulish;
              font-size: 15px;
              font-style: normal;
              font-weight: 800;
              line-height: 17px;
              letter-spacing: 0em;
              color: #fff;
              padding-top: 14.5px;
              padding-bottom: 14.5px;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                box-shadow: none;
                background-color: ${palette.primary.main};
              }
            `}
            variant="contained"
            onClick={() => {window.location.href = 'https://verikai.com';}}
          >
            Done
          </Button>
        </div>
      </DisplayMain>
    </DisplayRoot>;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Done));
