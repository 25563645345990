export const links = {
    "website": {
        "name": "https://www.radionhealth.com",
        "link": "https://www.radionhealth.com"
    },
    "portal": {
        "link": "https://portal.radionhealth.com"
    },
    "support" : {
        "name": "support@radionhealth.com",
        "link": "support@radionhealth.com"
    }
}