import React from 'react';
import 'styled-components/macro';

import TextField from '@material-ui/core/TextField';

import { palette } from '../../theme';

export default class AutocompleteTextField extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      focused: false,
    };
  }

  handleFocus()
  {
    this.setState({
      focused: true,
    });
  }

  handleBlur()
  {
    this.setState({
      focused: false,
    });
  }

  render()
  {
    const {
      label,
      ...other
    } = this.props;

    return <TextField
      css={`
        background-color: #fff;
        border-radius: 5px;
        border: none;
        & .MuiInputBase-root {
          min-height: 50px;
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
        }
        & .MuiOutlinedInput-root {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
          color: ${palette.secondary.dark};
          .MuiOutlinedInput-notchedOutline {
            border: ${
              this.state.focused
                ? `2px solid ${palette.secondary.dark}`
                : `1px solid ${palette.secondary.sat30}`
            };
          }
          &:hover .MuiOutlinedInput-notchedOutline {
            border: ${
              this.state.focused
                ? `2px solid ${palette.secondary.dark}`
                : `1px solid ${palette.secondary.sat30}`
            };
          }
        }
        & .MuiAutocomplete-inputRoot{
          padding-left: 20px;
          padding-bottom: 0px;
          padding-top: 0px;
        }
      `}
      variant="outlined"
      {...other}
    />;
  }
}
