import {
  API,
  Auth,
} from 'aws-amplify';
import { store } from '../../redux/store';
import setSnack from '../../redux/actions/setSnack';
import config from './config.json';

export default class CustomAPI
{
  constructor(options={})
  {
    this.apiName = options.hasOwnProperty('apiName')
      ? options.apiName
      : config.apiName;
  }

  async get(options={})
  {
    await this.setCognitoId();
    let path = options.hasOwnProperty('path')
      ? options.path
      : null;
    let errorMessage = options.hasOwnProperty('errorMessage')
      ? options.errorMessage
      : 'Unable to get data!';
    let config = options.hasOwnProperty('config')
      ? options.config
      : {};
    config = {
      ...config,
      ...(this.cognitoId && {
        headers: {
          "verikai-cognito-id": this.cognitoId
        },
      }),
    }

    let result;
    try
    {
      result = await API.get(this.apiName, path, config);
    }
    catch(err)
    {
      console.log(err);
      await store.dispatch(setSnack({
        variant: 'error',
        message: errorMessage,
      }));
      result = undefined;
    }
    return result;
  }

  async fetcher(options={}, type="post")
  {
    await this.setCognitoId();
    let path = options.hasOwnProperty('path')
      ? options.path
      : null;
    let body = options.hasOwnProperty('body')
      ? options.body
      : undefined;
    let headers = options.hasOwnProperty('headers')
    ? options.headers
    : {};
    headers = {
      ...headers,
      ...(this.cognitoId && {
         "verikai-cognito-id": this.cognitoId
      }),
    };
    let errorMessage = options.hasOwnProperty('errorMessage')
      ? options.errorMessage
      : 'Unable to send data!';
    let config = {
      ...(body && {
        body,
      }),
      headers,
    };

    let result;
    try
    {
      if(type === 'post')
      {
        result = await API.post(this.apiName, path, config);
      }
      else if(type === 'put')
      {
        result = await API.put(this.apiName, path, config);
      }
      else if(type === 'delete')
      {
        result = await API.del(this.apiName, path, config);
      }
    }
    catch(err)
    {
      await store.dispatch(setSnack({
        variant: 'error',
        message: errorMessage,
      }));
      result = undefined;
    }
    return result;
  }

  async put(options={})
  {
    let ret = await this.fetcher(options, 'put');
    return ret;
  }

  async post(options={})
  {
    let ret = await this.fetcher(options, 'post');
    return ret;
  }

  async delete(options={})
  {
    let ret = await this.fetcher(options, 'delete');
    return ret;
  }

  async setCognitoId() {
    if (window.Cypress && window.Cypress.platform === 'linux') {
      this.cognitoId = undefined;
      return;
    }
    if(process.env.NODE_ENV === 'development') {
      try {
        let cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: false });
        this.cognitoId = cognitoUser.attributes.sub;
      }
      catch(err) {
        this.cognitoId = undefined;
      }
    }
    else {
      this.cognitoId = undefined;
    }
  }
}
