import React from 'react';
import 'styled-components/macro';

import { AnimatePresence, motion } from "framer-motion";
import Button from '@material-ui/core/Button';
import Display from './Display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@material-ui/core/CircularProgress';

import API from '../lib/API';

import { connect } from 'react-redux';
import {
  faArrowRight,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { palette } from '../theme';
import setUser from '../redux/actions/setUser';
import { withRouter } from "react-router-dom";

const mapDispatchToProps = dispatch => ({
  setUser: v => dispatch(setUser(v)),
});

const mapStateToProps = state => ({
  user: state.user,
  prepopulate: state.prepopulate,
});

class VerifyEmail extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      char: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
      },
      init: false,
      submitting: false,
      success: false,
      failure: false,
      locked: false,
    };

    this.charRefs = {
      1: React.createRef(),
      2: React.createRef(),
      3: React.createRef(),
      4: React.createRef(),
      5: React.createRef(),
    };
  }

  componentDidMount()
  {
    if(!this.props.user.email)
    {
      this.props.history.push('/create-account');
    }
    else if(this.props.user.email && this.props.user.emailVerified)
    {
      this.props.history.push('/account-type');
    }
    this.setupInputs();
  }

  componentDidUpdate()
  {
    this.setupInputs();
  }

  setupInputs()
  {
    if(!this.state.init && this.charRefs[1].current)
    {
      this.setState({
        init: true,
      });
      this.charRefs[1].current.focus();

      for(let key in this.charRefs)
      {
        this.charRefs[key].current.onkeydown = (e) => {
          let keyPressed = e.keyCode || e.charCode;
          if(keyPressed === 8 || keyPressed === 46)
          {
            this.handleBackspace(key);
          }
        };
      }
    }
  }

  handleBackspace(charNumber)
  {
    if(charNumber > 1 && this.charRefs[charNumber].current.value === '')
    {
      this.charRefs[charNumber - 1].current.focus();
    }
  }

  handleChangeChar(e)
  {
    let charNumber = parseInt(e.target.name);
    for(let i = 1; i <= charNumber; i++)
    {
      if(this.state.char[i] === '')
      {
        charNumber = i;
        break;
      }
    }
    let char = e.target.value
      ? e.target.value[e.target.value.length - 1]
      : '';

    if(charNumber < 5)
    {
      this.setState({
        char: {
          ...this.state.char,
          [charNumber]: char,
        },
        failure: false,
        success: false,
        submitting: false,
      }, () => {
        if(char !== '')
        {
          this.charRefs[charNumber + 1].current.focus();
        }
      });
    }
    else
    {
      this.charRefs[charNumber].current.blur();
      this.setState({
        char: {
          ...this.state.char,
          [charNumber]: char,
        },
        failure: false,
        success: false,
        submitting: false,
      }, this.handleSubmit.bind(this));
    }
  }

  async handleFocus(e)
  {
    if(this.state.failure)
    {
      this.setState({
        char: {
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
        },
        failure: false,
        success: false,
        submitting: false,
      });
      this.charRefs[1].current.value = '';
      this.charRefs[2].current.value = '';
      this.charRefs[3].current.value = '';
      this.charRefs[4].current.value = '';
      this.charRefs[5].current.value = '';
      this.charRefs[1].current.focus();
    }
    else
    {
      let charNumber = parseInt(e.target.name);
      for(let i = 1; i <= charNumber; i++)
      {
        if(this.state.char[i] === '')
        {
          charNumber = i;
          break;
        }
      }
      this.charRefs[charNumber].current.focus();
    }
  }

  async handleResendCode(e)
  {
    e.preventDefault();
    if(!this.state.success && !this.state.submitting && !this.state.locked)
    {
      let api = new API();
      await api.get({
        path: `/verify-email?email=${this.props.user.email}`
      });
    }
  }

  async handleSubmit()
  {
    let code = Object.keys(this.state.char).map(n => this.state.char[n]).join('');
    this.setState({
      submitting: true,
    });
    let api = new API();
    let result = await api.post({
      path: `/verify-email`,
      body: {
        email: this.props.user.email,
        code,
      },
    });

    if(result !== 'success')
    {
      this.setState({
        failure: result === 'Too many attempts.'
          ? 'Too many attempts'
          : 'Incorrect code',
        submitting: false,
        locked: result === 'Too many attempts.',
      });
    }
    else
    {
      this.setState({
        submitting: false,
        success: true,
      }, () => this.props.setUser({emailVerified: true}));
    }
  }

  handleBack()
  {
    this.props.history.push('/create-account');
  }

  handleNext()
  {
    if(this.props.prepopulate) {
      this.props.user.accountType === 'Broker' ?
        this.props.history.push('/account-setup') 
        : this.props.history.push('/business-information');
    } else if (this.props.user.accountType === 'Broker') {
      this.props.history.push('/account-setup');
    } else {
      this.props.history.push('/account-type');
    }
  }

  render()
  {
    const disabled = this.state.success || this.state.submitting || this.state.locked;
    return <Display
      title="Before we begin.."
      outerBottom={<div
        className='outerBottomDiv'
        css={`
          display: flex;
          justify-content: space-between;
          margin-top: 32px;
          @media (max-width: 850px) {
            background-color: ${palette.secondary.light50};
            flex-direction: column-reverse;
            padding:0 30px;
            margin: 0;
          }
        `}
      >
        <Button
        className='website-link'
          css={`
            color: ${palette.link.blue};
            text-transform: none;
            font-family: Mulish;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 0em;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 5px;
            text-decoration: underline;
          `}
          onClick={this.handleBack.bind(this)}
        >
          Back
        </Button>

        <Button
          className='next-button'
          css={`
            background-color: ${palette.primary.main};
            box-shadow: none;
            margin-top: 7px;
            text-transform: none;
            font-family: Mulish;
            font-size: 15px;
            font-style: normal;
            font-weight: 800;
            line-height: 17px;
            letter-spacing: 0em;
            color: #fff;
            padding-top: 14.5px;
            padding-bottom: 14.5px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              box-shadow: none;
              background-color: ${palette.primary.main};
            }
            margin-bottom: 17px;
          `}
          variant="contained"
          type="submit"
          disabled={!this.state.success || !this.props.user.emailVerified}
          onClick={this.handleNext.bind(this)}
        >
          <div className='next-button'>
            Next
          </div>
          <FontAwesomeIcon
            css={`
              margin-left: 10px;
              font-size: 12px;
              font-weight: 900;
            `}
            icon={faArrowRight}
          />
        </Button>
      </div>}
    >

      {/* Subtitle */}
      <div
        css={`
          font-family: Mulish;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: left;
          color: ${palette.secondary.dark};
          display: flex;
          margin-bottom: 32px;

          @media (max-width: 850px) {
            display: inline;
            text-align: center;
          }
        `}
      >
        Please enter the <span css={`font-weight: 700; padding: 0 4px;`}> verification code </span> that was emailed to you.
      </div>

      {/* Verification Input Container */}
      <div
        css={`
          display: flex;
          justify-content: center;
          @media (max-width: 850px) {
            padding-top: 32px;
          }
        `}
      >
        {
          Array(5).fill('').map((row, idx) => <>
            {
              idx !== 0
                ? <div
                  css={`
                    width: 10.5px;
                  `}
                />
                : null
            }
            <Input
              value={this.state.char[idx + 1]}
              name={`${idx + 1}`}
              onChange={this.handleChangeChar.bind(this)}
              outerRef={this.charRefs[idx + 1]}
              disabled={disabled}
              locked={this.state.locked}
              failure={this.state.failure}
              success={this.state.success}
              onFocus={this.handleFocus.bind(this)}
              autoFocus={idx === 0}
            />
          </>)
        }
      </div>

      {/* Progress Container */}
      <div
        css={`
          height: 52px;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <AnimatePresence
          exitBeforeEnter
        >
          {this.state.submitting && (<motion.div
            key="motionsubmitting"
            initial={{ opacity: 0, }}
            animate={{ opacity: 1, }}
            exit={{ opacity: 0, }}
            transition={{
              type: "spring",
              damping: 10,
              stiffness: 100
            }}
          >
            <CircularProgress
              style={{
                height: '24px',
                width: '24px',
              }}
              color="secondary"
            />
          </motion.div>)}

          {this.state.success && (<motion.div
              key="motionsuccess"
              initial={{ opacity: 0, y: -5, }}
              animate={{ opacity: 1, y: 0, }}
              exit={{ opacity: 0, y: -5, }}
              transition={{
                type: "spring",
                damping: 10,
                stiffness: 100
              }}
            >
              <FontAwesomeIcon
                css={`
                  font-size: 24px;
                  color: ${palette.primary.main};
                `}
                icon={faCheck}
              />
            </motion.div>)}

          {this.state.failure && (<motion.div
            key="motionfailure"
            initial={{ opacity: 0, y: -5, }}
            animate={{ opacity: 1, y: 0, }}
            exit={{ opacity: 0, y: -5, }}
            transition={{
              type: "spring",
              damping: 10,
              stiffness: 100
            }}
          >
            <div
              css={`
                font-family: Mulish;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
                letter-spacing: 0em;
                color: ${palette.error.main};
              `}
            >
              {this.state.failure}
            </div>
          </motion.div>)}
        </AnimatePresence>
      </div>

      {/* Helper Text */}
      <div
        css={`
          font-family: Mulish;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          display: flex;
          justify-content: center;
          color: ${palette.secondary.dark};
          font-weight: 700;
        `}
      >
        Haven't received your code?
      </div>

      {/* Resend Code Link */}
      <div
        css={`
          width: 100%;
          display: flex;
          justify-content: center;
        `}
      >
        <a
        className='website-link'
          css={`
            font-family: Mulish;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            color: ${
              disabled
                ? '#cccccc'
                : palette.link.blue
            };
            font-weight: 400;
            ${
              disabled
                ? `cursor: default;`
                : ''
            }
          `}
          href="#"
          onClick={this.handleResendCode.bind(this)}
        >
          Resend code
        </a>
      </div>
    </Display>;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyEmail));

class Input extends React.Component
{

  handleChange(e)
  {
    this.props.onChange(e);
    this.props.outerRef.current.value = this.props.value;
  }

  render()
  {
    return <input
      className='text-input'
      css={`
        width: 58px;
        height: 50px;
        border-radius: 5px;
        border: 1px solid ${palette.secondary.sat30};
        background-color: #fff;
        text-align: center;
        font-family: Mulish;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0em;
        color: ${palette.secondary.dark};
        ${
          this.props.locked
            ? `background-color: ${palette.error.main40};`
          : this.props.success
            ? `background-color: ${palette.primary.main25};`
          : this.props.disabled
            ? `background-color: #f2f2f2;`
          : this.props.failure
            ? `background-color: ${palette.error.main40};`
            : ''
        }
      `}
      value={this.props.value}
      name={this.props.name}
      onChange={this.handleChange.bind(this)}
      ref={this.props.outerRef}
      autoFocus={this.props.autofocus}
      disabled={this.props.disabled}
      onFocus={this.props.onFocus}
    />;
  }
}
