import {
  SET_PASSWORD,
} from "../actionTypes";

export default function setPassword(v) {
  return {
    type: SET_PASSWORD,
    payload: v,
  };
}
