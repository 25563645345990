Object.defineProperty(exports, "__esModule", {
  value: true
});

var _reduxPersist = require('redux-persist');

var _core = require('crypto-js/core');

var _core2 = _interopRequireDefault(_core);

var _aes = require('crypto-js/aes');

var _aes2 = _interopRequireDefault(_aes);

var _helpers = require('./helpers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var makeSyncEncryptor = function makeSyncEncryptor(secretKey) {
  return (0, _helpers.makeEncryptor)(function (state) {
    return _aes2.default.encrypt(state, secretKey).toString();
  });
};

var makeSyncDecryptor = function makeSyncDecryptor(secretKey, onError) {
  return (0, _helpers.makeDecryptor)(function (state) {
    var bytes, decryptedString;
    try {
      bytes = _aes2.default.decrypt(state, secretKey);
      decryptedString = bytes.toString(_core2.default.enc.Utf8);
      return JSON.parse(decryptedString);
    } catch (err) {
      if
      (
        (err.message.includes('Unexpected token') && err.message.includes('in JSON at position 0'))
        || (err.message.includes('JSON Parse error: Unexpected EOF'))
        || (err.message.includes('unexpected character at') && err.message.includes('of the JSON data'))
        || (err.message.includes('unexpected end of data at') && err.message.includes('of the JSON data"'))
      )
      {
        bytes = _aes2.default.decrypt(state, secretKey);
        decryptedString = bytes.toString(_core2.default.enc.Utf8);
        return decryptedString;
      }
      else
      {
        throw new Error('Could not decrypt state. Please verify that you are using the correct secret key.');
      }
    }
  }, onError);
};

exports.default = function (config) {
  var inbound = makeSyncEncryptor(config.secretKey);
  var outbound = makeSyncDecryptor(config.secretKey, config.onError);
  return (0, _reduxPersist.createTransform)(inbound, outbound, config);
};
