// Setup-1. Import React/Redux
import { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

// Setup-2. Import Libs
import {
  faGlobe,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { fetchImage } from '../lib/fetchImage';
import { palette } from '../theme';
import 'styled-components/macro';

// Setup-3. Import Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from './Typography';
import { Link } from './Link';

// Setup-4. Props Interface
interface Props {
  description?: string | null;
  headquartersLocation: string | null;
  logoPath?: string | null | undefined;
  name: string;
  website?: string | null;
};

export const BusinessCard = (props: Props) => {

  // 1. State Interface
  interface State {
    init: boolean;
    logo?: string | undefined;
  }

  // 2. Initial State
  const initialState:State = {
    init: false,
    logo: undefined,
  };

  // 3. Set State Function
  const [state, setState] = useState<State>(initialState);
  const isBroker = useSelector((state: RootStateOrAny) => state.user.accountType === 'B')

  // 4. Use Effect -> When logoPath updates, fetch it
  useEffect(() => {
    async function executeFetch() {
      if(props.logoPath) {
        await fetchLogo();
      }
      if(!state.init) {
        setState({
            ...state,
             init: true,
        });
      }
    }

    executeFetch();
  }, [props.logoPath]); // eslint-disable-line react-hooks/exhaustive-deps

  // 5. Get the logo
  async function fetchLogo() {
    let logo: string | undefined = await fetchImage({
      route: props.logoPath
    });
    setState({
        ...state,
        logo,
    });
  }

  // 999. Render
  return <div
            css={`
            margin: 23.55px;
            box-shadow: inset 0px 4px 0px rgba(242, 174, 0, 0.4);
            border-radius: 10px;
            background-color: #FFFFFF;
            border: 1px solid ${palette.secondary.sat17};
            padding-top: 28px;
            padding-left: 28px;
            padding-right: 28px;
            padding-bottom: 30px;
            margin-bottom: 23.55px;
            `}
        >
            <div
            css={`
                display: flex;
            `}
            >
            <div
                css={`
                flex-grow: 1;
                `}
            >
                <Typography
                component="p"
                color={palette.secondary.dark80}
                size="10px"
                lineHeight="15px"
                >
                {`
                    About this underwriter
                `}
                </Typography>
                <Typography
                component="h2"
                weight="700"
                size="18px"
                lineHeight="23.4px"
                >
                {props.name}
                </Typography>
            </div>
            <div>
                {
                state.logo
                && state.init
                    ? <img
                    alt={`${props.name}'s Logo`}
                    css={`
                        max-height: 38px;
                        width: auto;
                        max-width: 174px;
                        height: auto;
                    `}
                    src={state.logo}
                    />
                    : <div
                    css={`
                        height: 38px;
                        width: 174px;
                    `}
                    />
                }
            </div>
            </div>
            {
            props.description
            && <div
                css={`
                margin-top: 22px;
                `}
            >
                <Typography
                color={palette.secondary.dark60}
                >
                {props.description}
                </Typography>
            </div>
            }
            {
            (
                props.headquartersLocation
                || props.website
            ) && <div
                css={`
                display: flex;
                margin-top: 28px;
                `}
            >
                {
                props.headquartersLocation
                && <div
                    css={`
                    display: flex;
                    align-items: center;
                    `}
                >
                    <FontAwesomeIcon
                    css={`
                        color: ${palette.secondary.dark};
                        font-size: 14px;
                        margin-right: 6px;
                    `}
                    icon={faHome}
                    />
                    <Typography
                    lineHeight="11.5px"
                    size="10px"
                    textTransform="uppercase"
                    weight={700}
                    >
                    {props.headquartersLocation}
                    </Typography>
                </div>
                }
                {
                props.headquartersLocation
                && props.website
                && <div
                    css={`
                    width: 22.5px;
                    `}
                />
                }
                {
                props.website
                && <Link
                    id="business-card-website"
                    newTab
                    src={props.website}
                    css={`
                    line-height: "11.5px",
                    size: "10px",
                    text-transform: "uppercase",
                    weight: 700,
                    `}
                >
                    <FontAwesomeIcon
                    css={`
                        color: ${palette.secondary.dark};
                        font-size: 14px;
                        margin-right: 6px;
                    `}
                    icon={faGlobe}
                    />
                    {props.website}
                </Link>
                }
            </div>
            }
        </div>;
}
