import React from 'react';
import 'styled-components/macro';

import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import Display from './Display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Steps from './Steps';
import TextField from './verikai/TextFieldBase';
import Tier from './Tier';

import { connect } from 'react-redux';
import brokerFormSteps from '../lib/brokerFormSteps';
import {
  faArrowRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { palette } from '../theme';
import prettyInt from '../lib/prettyInt';
import setUser from '../redux/actions/setUser';
import { withRouter } from "react-router-dom";

const mapDispatchToProps = dispatch => ({
  setUser: v => dispatch(setUser(v)),
});

const mapStateToProps = state => ({
  user: state.user,
});

class TierPlan extends React.Component {

  constructor(props)
  {
    super(props);
    this.state = {
      calculuateProModalOpen: false,
      calculuateProModalScreen: 1,
    };
  }

  componentDidMount()
  {
    const b = this.props.user.businessInformation;
    if(!this.props.user.email || !this.props.user.emailVerified)
    {
      this.props.history.push('/create-account');
    }
    else if(!this.props.user.accountType)
    {
      this.props.history.push('/account-type');
    }
    else if
    (
      !b.brokerageName
    )
    {
      this.props.history.push('/business-information');
    }
  }

  handleBack()
  {
    this.props.history.push('/business-information');
  }

  handleSelect(v)
  {
    this.props.setUser({
      tierPlan: v,
    });

    this.props.history.push('/account-setup');
  }

  handleSelectEnterprise(v)
  {
    this.props.setUser({
      tierPlan: v,
    });

    this.props.history.push('/person-to-contact-enterprise');
  }

  getProCost()
  {
    let v = parseInt(this.props.user.businessInformation.stoplossGroupsPerYear.replace(/,|\D/g, ''));
    if(!isNaN(v))
    {
      if(v < 100)
      {
        return '2,500';
      }
      else if(v >= 100 && v < 250)
      {
        return '5,000';
      }
      else if(v >= 250 && v < 500)
      {
        return '7,500';
      }
      else
      {
        return '10,000';
      }
    }
    else
    {
      return '10,000';
    }
  }

  handleOpenCalcuatePro()
  {
    this.setState({
      calculuateProModalOpen: true,
    });
  }

  handleCloseCalcuatePro()
  {
    this.setState({
      calculuateProModalOpen: false,
    });
  }

  handleChangeStoplossGroupsPerYear(e)
  {
    this.props.setUser({
      businessInformation: {
        ...this.props.user.businessInformation,
        stoplossGroupsPerYear: prettyInt(e.target.value, true, this.props.user.businessInformation.stoplossGroupsPerYear),
      },
    });
  }

  render()
  {
    const calculateProRequired = isNaN(parseInt(this.props.user.businessInformation.stoplossGroupsPerYear.replace(/,|\D/g, '')));
    return <>
      <Display
        title="Choose a tier plan"
        wide
        outerTop={<Steps
          currentStepNumber={2}
          steps={brokerFormSteps}
        />}
        outerBottom={<div
          css={`
            display: flex;
            justify-content: flex-start;
            margin-top: 32px;
          `}
        >
          <Button
            css={`
              color: ${palette.link.blue};
              text-transform: none;
              font-family: Mulish;
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: 17px;
              letter-spacing: 0em;
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 10px;
              padding-bottom: 10px;
              border-radius: 5px;
            `}
            onClick={this.handleBack.bind(this)}
          >
            Back
          </Button>
        </div>}
      >

        {/* Subtitle */}
        <div
          css={`
            font-family: Mulish;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: left;
            color: ${palette.secondary.dark};
            display: flex;
            margin-bottom: 38px;
          `}
        >
          Choose a tier option that meets your needs. You can upgrade/downgrade your plan at anytime.
        </div>

        {/* Tier Container */}
        <div
          css={`
            display: flex;
          `}
        >
          <Tier
            title="Basic"
            description="For individuals or teams just getting started"
            costTitle="Free"
            featureListTitle="Included features: "
            featureList={[
              {
                label: 'Unlimited census submissions',
                available: true,
              },
              {
                label: 'Unlimited users',
                available: true,
              },
              {
                label: 'Quotable/Not Quotable',
                available: true,
              },
              {
                label: 'Explainability',
                available: false,
              },
              {
                label: 'Total Expected Claims',
                available: false,
              },
              {
                label: 'Risk Scores',
                available: false,
              },
            ]}
            onSelect={this.handleSelect.bind(this)}
          />
          <div
            css={`
              width: 38px;
            `}
          />
          <Tier
            title="Standard"
            description="Qualitative risk insight about your group's strengths and weaknesses."
            recommended
            costTitle="$1,000"
            costSubtitle="monthly"
            featureListTitle="Everything in Basic plus more:"
            featureList={[
              {
                label: 'Unlimited census submissions',
                available: true,
              },
              {
                label: 'Unlimited users',
                available: true,
              },
              {
                label: 'Quotable/Not Quotable',
                available: true,
              },
              {
                label: 'Explainability',
                available: true,
              },
              {
                label: 'Total Expected Claims',
                available: false,
              },
              {
                label: 'Risk Scores',
                available: false,
              },
            ]}
            onSelect={this.handleSelect.bind(this)}
          />
          <div
            css={`
              width: 38px;
            `}
          />
          <Tier
            title="Pro"
            description="Advanced decision making advice to provide tailored group coverage."
            costIsCustom={calculateProRequired || this.state.calculuateProModalOpen}
            costTitle={
              calculateProRequired || this.state.calculuateProModalOpen
                ? undefined
                : `$${this.getProCost()}`
            }
            costSubtitle="monthly"
            featureListTitle="Everything in Standard plus more:"
            featureList={[
              {
                label: 'Unlimited census submissions',
                available: true,
              },
              {
                label: 'Unlimited users',
                available: true,
              },
              {
                label: 'Quotable/Not Quotable',
                available: true,
              },
              {
                label: 'Explainability',
                available: true,
              },
              {
                label: 'Total Expected Claims',
                available: true,
              },
              {
                label: 'Risk Scores',
                available: false,
              },
            ]}
            onSelect={
              calculateProRequired
                ? this.handleOpenCalcuatePro.bind(this)
                : this.handleSelect.bind(this)
            }
            selectLabel={
              calculateProRequired || this.state.calculuateProModalOpen
                ? 'Calculate Cost'
                : undefined
            }
          />
          <div
            css={`
              width: 38px;
            `}
          />
          <Tier
            title="Enterprise"
            description="Must have features for businesses that provide benefits to lots of groups."
            costIsCustom
            featureListTitle="Full access to our entire suite of tools:"
            featureList={[
              {
                label: 'Unlimited census submissions',
                available: true,
              },
              {
                label: 'Unlimited users',
                available: true,
              },
              {
                label: 'Quotable/Not Quotable',
                available: true,
              },
              {
                label: 'Explainability',
                available: true,
              },
              {
                label: 'Total Expected Claims',
                available: true,
              },
              {
                label: 'Risk Scores',
                available: true,
              },
            ]}
            onSelect={this.handleSelectEnterprise.bind(this)}
          />
        </div>
      </Display>
      <Dialog
        css={`
          .MuiDialog-paper {
            border-radius: 10px;
            background-color: ${palette.secondary.light25};
            padding-left: 45px;
            padding-right: 45px;
            padding-top: 16px;
            padding-bottom: 29px;
            min-width: 797px;
          }
        `}
        open={this.state.calculuateProModalOpen}
      >

        {/* Calculate Pro Modal Toolbar */}
        <div
          css={`
            display: flex;
            align-items: center;
            margin-bottom: 26px;
          `}
        >

          {/* Modal Title */}
          <div
            css={`
              font-family: Mulish;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 18px;
              letter-spacing: 0em;
              text-align: left;
              color: ${palette.secondary.dark};
              flex-grow: 1;
            `}
          >
            Calculate Pro tier
          </div>

          {/* Modal Close Button */}
          <div
            css={`
              text-align: right;
            `}
          >
            <Button
              css={`
                display: flex;
                align-items: center;
                justify-content: center;
                color: ${palette.secondary.dark};
                font-family: Mulish;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 12px;
                letter-spacing: 0.05em;
                text-align: left;
              `}
              onClick={this.handleCloseCalcuatePro.bind(this)}
            >
              <div
                css={`
                  margin-right: 6px;
                `}
              >
                CLOSE
              </div>
              <FontAwesomeIcon
                css={`
                  color: ${palette.secondary.dark};
                `}
                icon={faTimes}
              />
            </Button>
          </div>
        </div>

        {
          this.state.calculuateProModalScreen === 1
            ? <>
              {/* Modal Inner Container */}
              <div
                css={`
                  background: ${palette.secondary.light50};
                  border: 1px solid ${palette.secondary.sat17};
                  border-radius: 5px;
                  width: 100%;
                  padding-top: 40px;
                  padding-bottom: 60px;
                  padding-left: 30px;
                  padding-right: 30px;
                  margin-bottom: 32px;
                `}
              >

                {/* Modal Inner Container Title */}
                <div
                  css={`
                    font-family: Mulish;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 42px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: ${palette.secondary.dark};
                    margin-bottom: 31.5px;
                  `}
                >
                  Determine your Pro tier custom pricing
                </div>

                {/* Modal Inner Container Body */}
                <div
                  css={`
                    font-family: Mulish;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: ${palette.secondary.sat};
                    margin-bottom: 31.5px;
                  `}
                >
                  Pricing is determined based on the amount of groups a business places with stop loss products per year.
                </div>

                {/* Modal Form Container */}
                <div
                  css={`
                    display: flex;
                    align-items: center;
                    border-radius: 5px;
                    background-color: ${palette.secondary.sat17};
                    width: 100%;
                    padding: 15px;
                    justify-content: center;
                  `}
                >

                  {/* Modal Form Label */}
                  <div
                    css={`
                      font-family: Mulish;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 21px;
                      letter-spacing: 0.04em;
                      text-align: left;
                      color: ${palette.secondary.dark};
                      width: 356.5px;
                    `}
                  >
                    Please enter the number of groups your business places with stop loss products per year.
                  </div>

                  <div
                    css={`
                      flex-grow: 1;
                      margin-left: 18px;
                    `}
                  >
                    <TextField
                      fullWidth
                      onChange={this.handleChangeStoplossGroupsPerYear.bind(this)}
                      name="stoplossGroupsPerYear"
                      value={this.props.user.businessInformation.stoplossGroupsPerYear}
                      helperTextOff
                      placeholder="Ex. 110"
                    />
                  </div>
                </div>
              </div>

              {/* Calculate Button */}
              <div
                css={`
                  display: flex;
                  justify-content: flex-end;
                `}
              >
                <Button
                  css={`
                    background-color: ${palette.primary.main};
                    box-shadow: none;
                    margin-top: 7px;
                    text-transform: none;
                    font-family: Mulish;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 17px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #fff;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover {
                      box-shadow: none;
                      background-color: ${palette.primary.main};
                    }
                  `}
                  variant="contained"
                  onClick={() => this.setState({calculuateProModalScreen: 2})}
                  disabled={calculateProRequired}
                >
                  <div>
                    Calculate my pricing
                  </div>
                  <FontAwesomeIcon
                    css={`
                      margin-left: 10px;
                      font-size: 12px;
                      font-weight: 900;
                    `}
                    icon={faArrowRight}
                  />
                </Button>
              </div>
            </>
            : <>

              {/* Modal Content Title */}
              <div
                css={`
                  margin-top: 28.7px;
                  font-family: Mulish;
                  font-size: 32px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 42px;
                  letter-spacing: 0em;
                  text-align: center;
                  color: ${palette.secondary.dark};
                  margin-bottom: 16px;
                `}
              >
                Your calculated Pro tier pricing is
              </div>

              {/* Modal Inner Container */}
              <div
                css={`
                  background: ${palette.secondary.light50};
                  border: 1px solid ${palette.secondary.sat17};
                  border-radius: 5px;
                  width: 100%;
                  padding-top: 32px;
                  padding-bottom: 25px;
                  margin-bottom: 34.7px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >

                {/* Modal Inner Container Title */}
                <div
                  css={`
                    font-family: Mulish;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 52px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: ${palette.secondary.dark};
                  `}
                >
                  {`$${this.getProCost()}`}
                </div>

                {/* Modal Inner Container Subscript */}
                <div
                  css={`
                    font-family: Mulish;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    margin-left: 10px;
                    color: ${palette.secondary.dark60};
                  `}
                >
                  monthly
                </div>
              </div>

              {/* Calculate Button */}
              <div
                css={`
                  display: flex;
                  justify-content: space-between;
                `}
              >
                <Button
                  css={`
                    color: ${palette.link.blue};
                    text-transform: none;
                    font-family: Mulish;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 17px;
                    letter-spacing: 0em;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-radius: 5px;
                  `}
                  onClick={() => this.setState({calculuateProModalScreen: 1})}
                >
                  Back
                </Button>
                <Button
                  css={`
                    background-color: ${palette.primary.main};
                    box-shadow: none;
                    margin-top: 7px;
                    text-transform: none;
                    font-family: Mulish;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 17px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #fff;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover {
                      box-shadow: none;
                      background-color: ${palette.primary.main};
                    }
                  `}
                  variant="contained"
                  onClick={() => this.handleSelect('Pro')}
                  disabled={calculateProRequired}
                >
                  <div>
                    Select this plan
                  </div>
                </Button>
              </div>
            </>
        }

      </Dialog>
    </>;
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TierPlan));
