import React from 'react';

class VerikaiSpacer extends React.Component {

  render()
  {
    const {
      pixels,
      horizontal,
    } = this.props;

    return (<div
      style={{
        ...(pixels && {
          [horizontal ? 'width' : 'height']: `${pixels}px`,
        }),
      }}
    >
    </div>);
  }
}

export default VerikaiSpacer;
