// Setup-1. Import React/Redux
import React from 'react';

// Setup-2. Import Libs
import { palette } from "../theme";
import 'styled-components/macro';

// Setup-3. Import Components
/* NONE */

// Setup-4. Types/Interfaces
declare global {
  namespace JSX {
    interface IntrinsicElements {
      typography: any;
    }
  }
}

type TypographyComponentType = 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface TypographyComponentProps {
  children?: React.ReactNode;
  color?: string;
  component: TypographyComponentType;
  display?: string;
  ellipsisBreak?: boolean;
  filter?: string;
  lineHeight?: string;
  size?: string;
  textDecoration?: string;
  textTransform?: string;
  variant: 'default' | 'secondary';
  weight?: string | number;
  textAlign: string;
};

export interface TypographyProps {
  children?: React.ReactNode;
  color?: string;
  component?: TypographyComponentType;
  display?: string;
  ellipsisBreak?: boolean;
  filter?: string;
  lineHeight?: string;
  size?: string;
  textDecoration?: string;
  textTransform?: string;
  variant?: 'default' | 'secondary';
  weight?: string | number;
};

// Setup-999. Declarations
const css = {
  ellipsisBreak: `
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  flatten: `
    margin-block-start: unset;
    margin-block-end: unset;
    margin-block-inline-start: unset;
    margin-block-inline-end: unset;
  `,
  variant: {
    default: `
      font-family: Mulish, "Helvetica Nueue", Arial, sans-serif !important;
      font-style: normal;
    `,
    secondary: `
      font-family: Pridi, "Times New Roman", Arial, sans-serif;
      font-style: normal;
    `,
  },
}

export const Typography = (props: TypographyComponentProps) => {
  const typography: React.ReactNode = React.createElement(props.component); // eslint-disable-line @typescript-eslint/no-unused-vars

  return <typography
    css={`
      ${css.variant[props.variant]}
      ${css.flatten}
      ${props.ellipsisBreak ? css.ellipsisBreak : ''}
      display: ${props.display};
      color: ${props.color};
      font-size: ${props.size};
      font-weight: ${props.weight};
      line-height: ${props.lineHeight};
      text-decoration: ${props.textDecoration};
      text-transform: ${props.textTransform};
      text-align: ${props.textAlign};
    `}
  >
    {props.children}
  </typography>;
}

Typography.defaultProps = {
  color: palette.secondary.dark,
  component: 'p',
  display: 'block',
  ellipsisBreak: false,
  lineHeight: '21px',
  size: '14px',
  textDecoration: 'none',
  textTransform: 'none',
  variant: 'default',
  weight: '400',
  textAlign: "left"
};
