import React from 'react';
import 'styled-components/macro';

import Button from '@material-ui/core/Button';
import { DisplayLogo } from './Display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HelperText from './verikai/HelperText';
import InputBase from '@material-ui/core/InputBase';

import API from '../lib/API';

import { connect } from 'react-redux';
import emailValidTest from '../lib/emailValidTest';
import {
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import frontPage from '../assets/frontPage.png';
import { palette } from '../theme';
import setReferrerLogo from '../redux/actions/setReferrerLogo';
import setUser from '../redux/actions/setUser';
import { withRouter } from "react-router-dom";

const mapStateToProps = state => ({
  email: state.user.email,
  prepopulate: state.prepopulate,
  referrerLogo: state.referrerLogo,
});

const mapDispatchToProps = dispatch => {
  return {
    setReferrerLogo: v => dispatch(setReferrerLogo(v)),
    setUser: v => dispatch(setUser(v)),
  };
};

class CreateAnAccount extends React.Component {

  constructor(props)
  {
    super(props);
    this.state = {
      errorMessage: '',
      email: this.props.email || '',
      submitting: false,
    };
  }

  async componentDidMount()
  {
    await window.pendo.initialize();
    if(!this.props.prepopulate && this.props.referrerLogo) {
      this.props.setReferrerLogo('');
    }
    if(this.state.email && this.props.prepopulate) {
      await this.handleSubmit();
    }
  }

  handleChangeInput(e)
  {
    this.setState({
      [e.target.name]: e.target.value,
      errorMessage: '',
    });
  }

  async handleSubmit(e)
  {
    if(e) {
      e.preventDefault();
    }
    this.setState({
      submitting: true,
    });

    window.pendo.updateOptions({
      visitor: {
        email: this.state.email,
      },
    });

    // 1. Check Valid Email
    let valid = emailValidTest(this.state.email);
    if(!valid)
    {
      this.setState({
        errorMessage: 'You must enter a valid email',
      });
    }
    else
    {
      let api = new API();
      let valid = await api.get({
        path: `/check-email?email=${this.state.email}`,
      });
      if(valid.user_exists)
      {
        this.setState({
          errorMessage: 'Email is currently in use',
        });
      }
      else
      {
        let sendVerificationCode = await api.get({
          path: `/verify-email?email=${this.state.email}`
        });
        this.props.setUser({
          email: this.state.email,
          emailVerified: false,
        });
        if(sendVerificationCode)
        {
          this.props.history.push('/verify-email');
        }
      }
    }

    this.setState({
      submitting: false,
    });
  }

  render()
  {
    return <div
      css={`
        @media (min-width: 0px) {
          background: linear-gradient(270deg, #E5E4EF 0%, #46418A 60.89%);
        }
        @media (max-width: 776px) {
          background: #46418A;
        }
        width: 100vw;
        height: 100vh;
        overflow: auto;
      `}
    >

      <DisplayLogo/>
      <div
        css={`
          background-image: url(${frontPage});
          background-repeat: no-repeat;
          min-height: calc(100% - 54px);
          @media (min-width: 0px) {
            background-position: right 0px top 58.41px;
          }
          @media (max-width: 1370px) {
            background-position: right -50px top 58.41px;
            background-size: 700px;
          }
          @media (max-width: 1140px) {
            background-position: right -75px top 58.41px;
            background-size: 650px;
          }
          @media (max-width: 1020px) {
            background-position: right -150px top 58.41px;
            background-size: 625px;
          }
          @media (max-width: 920px) {
            background-position: right -175px top 58.41px;
            background-size: 500px;
          }
          @media (max-width: 776px) {
            background-size: 0px;
          }
        `}
      >

        {/* Root Container */}
        <div
          css={`
            padding-top: 58.41px;
            @media (min-width: 0px) {
              padding-left: 128px;
            }
            @media (max-width: 1280px) {
              padding-left: 50px;
            }
            @media (max-width: 1056px) {
              padding-left: 25px;
            }
            @media (max-width: 920px) {
              padding-left: 15px;
            }
            min-height: 100%;
          `}
        >
          

          {/* Create an Account Container */}
          <div
            css={`
              width: 420px;
              border-radius: 5px;
              background-color: ${palette.secondary.light25};
              margin-bottom: 42px;
              padding-left: 50px;
              padding-top: 33px;
              padding-right: 50px;
              padding-bottom: 20px;
            `}
          >

            {/* Create Account Title */}
            <div
              css={`
                font-family: Mulish;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 42px;
                letter-spacing: 0em;
                color: ${palette.secondary.dark};
                margin-bottom: 32px;
              `}
            >
              Create an account
            </div>

            {/* Input Label */}
            <div
              css={`
                font-family: Mulish;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                letter-spacing: 0.04em;
                color: ${palette.secondary.sat};
                margin-bottom: 7px;
              `}
            >
              Enter email address
            </div>

            {/* Input */}
            <form
              noValidate
              onSubmit={this.handleSubmit.bind(this)}
            >
              <InputBase
                autoFocus
                css={`
                  border: 1px solid ${palette.secondary.sat30};
                  border-radius: 5px;
                  height: 50px;
                  padding-left: 20px;
                  padding-top: 12px;
                  padding-bottom: 14px;
                  font-family: Mulish;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                  letter-spacing: 0em;
                  color: ${palette.secondary.sat};
                  margin-bottom: 7px;
                `}
                fullWidth
                name="email"
                onChange={this.handleChangeInput.bind(this)}
                disabled={this.state.submitting}
                value={this.state.email}
              />

              {/* Helper Text */}
              <HelperText
                error
                open={this.state.errorMessage}
              >
                {this.state.errorMessage}
              </HelperText>

              {/* Submit Button */}
              <Button
                fullWidth
                css={`
                  background-color: ${palette.warning.main};
                  box-shadow: none;
                  margin-top: 7px;
                  text-transform: none;
                  font-family: Mulish;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 800;
                  line-height: 21px;
                  letter-spacing: 0em;
                  color: ${palette.secondary.dark};
                  padding-top: 14.5px;
                  padding-bottom: 14.5px;
                  border-radius: 5px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  &:hover {
                    box-shadow: none;
                    background-color: ${palette.warning.main};
                  }
                  margin-bottom: 17px;
                `}
                variant="contained"
                type="submit"
                disabled={this.state.submitting}
              >
                <div>
                  {
                    this.state.submitting
                      ? 'Creating...'
                      : 'Get started'
                  }
                </div>

                {
                  this.state.submitting
                    ? null
                    : <FontAwesomeIcon
                      css={`
                        margin-left: 10px;
                        font-size: 12px;
                        font-weight: 900;
                      `}
                      icon={faArrowRight}
                    />
                }
              </Button>
            </form>
            <div
              css={`
                font-family: Mulish;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: center;
              `}
            >
              Already have an account?&nbsp;
              <a
                css={`
                  text-decoration: none;
                  color: ${palette.primary.main};
                  &:visited {
                    color: ${palette.primary.main};
                  }
                `}
                href="https://portal.verikai.com"
              >
                Sign in.
              </a>
            </div>
          </div>

          {/* Main Text */}
          <div
            css={`
              font-family: Mulish;
              font-size: 32px;
              font-style: normal;
              font-weight: 400;
              line-height: 42px;
              letter-spacing: 0em;
              color: ${palette.secondary.light25};
              width: 399px;
              height: 210px;
              padding-left: 11px;
            `}
          >
            Generating accurate, forward-looking risk measurements for individuals and groups.
          </div>

          {/* Sub Text */}
          <div
            css={`
              font-family: Mulish;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 23px;
              letter-spacing: 0em;
              color: ${palette.secondary.light25};
              width: 399px;
              height: 46px;
              padding-left: 11px;
            `}
          >
            Our robust database tracks 5,000+ behavioral attributes on 250+ million individuals.
          </div>
          <div
            css={`
              height: 50px;
            `}
          />
        </div>
      </div>
    </div>;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAnAccount));
