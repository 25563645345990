import React from 'react';
import 'styled-components/macro';

import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { palette } from '../../theme';

export default class Checkbox extends React.Component
{
  render()
  {
    return <Button
      variant="outlined"
      className='checkbox-input'
      css={`
        width: 26px;
        min-width: 26px;
        height: 26px;
        border-radius: 5px;
        box-shadow: 0px 2px 8px rgb(209 205 248 / 40%);
        border: 1px solid ${palette.secondary.sat30};
        padding: 0px !important;
        background-color: #fff;
        ${this.props.cssProps}
      `}
      onClick={
        this.props.onChange
          ? this.props.onChange
          : () => {}
      }
    >
      {
        this.props.checked
          ? <FontAwesomeIcon
            className='checkbox-icon'
            css={`
              color: ${palette.secondary.sat};
              font-size: 18px;
            `}
            icon={faCheck}
          />
          : null
      }
    </Button>
  }
}
