import React from "react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { store, persistor } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import theme from './theme';
import history from './lib/history';

export default class AppProvider extends React.Component
{
  render()
  {
    return(<Router
      history={history}
    >
      <MuiThemeProvider
        theme={theme}
      >
        <Provider
          store={store}
        >
          <PersistGate loading={null} persistor={persistor}>
            {this.props.children}
          </PersistGate>
        </Provider>
      </MuiThemeProvider>
    </Router>);
  }
}
