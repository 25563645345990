// Setup.1. Core
import 'styled-components/macro';
import { palette } from '../theme';
import { useSelector, RootStateOrAny } from 'react-redux';
import { Logos } from './Logos';
import { LandingPageJoinMarketplace } from './LandingPageJoinMarketplace';
import { LandingPageVideo } from './LandingPageVideo';
import { LandingPageFeatureList } from './LandingPageFeatureList';
import { LandingPageFeatureBox } from './LandingPageFeatureBox';
import { LandingPageMarketplaceImg } from './LandingPageMarkplaceImg';
import { LandingPageContactInfo } from './LandingPageContactInfo';
import { LandingPageToolbar } from './LandingPageToolbar';
import { LoginForm } from './LoginForm';
import { Button } from '@material-ui/core';
import Spacer from "./verikai/Spacer";
import React, { useEffect } from 'react';


// Root
export const LandingPageRoot = () => {

    // const [showModal, setModalStatus] = React.useState(false);
    const [offset, setOffset] = React.useState(0);
    const [width, setWidth] = React.useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleWindowChange);
        return () => {
            window.removeEventListener('resize', () => {})
          }
    }, []);

    useEffect(() => {
        if(width < 850) {
            const onScroll = () => setOffset(window.pageYOffset);
            window.removeEventListener('scroll', onScroll);
            window.addEventListener('scroll', onScroll, { passive: true });
            return () => window.removeEventListener('scroll', onScroll);
        }
    }, [width]);

    const debounce = (func: any, timeout: number = 100) => {
        let timer: any;
        return () => {
          clearTimeout(timer);
          timer = setTimeout(() => func.apply(this), timeout);
        };
    }
    const handleWindowChange = debounce(() => setWidth(window.innerWidth));

    if(width < 850) {
        return <div
            css={`
                width: 100%;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
            `}
        >
            <LandingPageToolbar 
                mobile={true}
                button={
                    <Button 
                        onClick={() => offset > 700 ? window.scrollTo(0, 0) : null} 
                        css={`
                            opacity: ${offset > 700 ? "100%" : "0%"};
                            color: ${palette.warning.main};
                            border: 2px solid ${palette.warning.main};
                            border-radius: 6px;
                            width: 100px;
                            text-transform: none;
                            padding: 3px 15px;
                            transition: all ease 0.5s;
                        `}
                    >
                        Sign Up
                    </Button>
                }
            />
            <Spacer pixels={90}/>
            <Logos/>
            <Spacer pixels={15}/>
            <LandingPageJoinMarketplace mobile={true}/>
            <Spacer pixels={40}/>
            <LoginForm mobile={true}/>
            {process.env.REACT_APP_WHITE_LABEL !== 'true' && <LandingPageVideo mobile={true}/>}
            <LandingPageFeatureList mobile={true}/>
            {process.env.REACT_APP_WHITE_LABEL !== 'true' && <Spacer pixels={45}/>}
            {process.env.REACT_APP_WHITE_LABEL !== 'true' && <LandingPageFeatureBox mobile={true}/>}
            {process.env.REACT_APP_WHITE_LABEL !== 'true' && <Spacer pixels={45}/>}
            {process.env.REACT_APP_WHITE_LABEL !== 'true' && <LandingPageMarketplaceImg mobile={true}/>}
            {process.env.REACT_APP_WHITE_LABEL !== 'true' && <Spacer pixels={45}/>}
            <LandingPageContactInfo mobile={true}/>
        </div>
    }
    // Root.999. Render

    return <div
        css={`
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
        `}
    >
        {/* TO-DO: complete modal alerting user of existing email */}
        {/* <EmailDetectedModal show={showModal} onClose={() => setModalStatus(false)}/> */}

        {/* Left Side*/}
        <div
            className='left-landing-page'
            css={`
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #E6E4F6 100%);
                width: 60%;
                padding: 50px 80px;
                padding-left: ${window.innerWidth*0.6-800 > 0 ? window.innerWidth*0.6-800 : 80 }px;
            `}
        >
            <LandingPageToolbar/>
            <Spacer pixels={50} />
            <Logos/>
            <Spacer pixels={50}/>
            <LandingPageJoinMarketplace />
            <Spacer pixels={80}/>
            {process.env.REACT_APP_WHITE_LABEL !== 'true' && <LandingPageVideo/>}
            {process.env.REACT_APP_WHITE_LABEL !== 'true' && <Spacer pixels={80}/>}
            <LandingPageFeatureList />
            <Spacer pixels={130}/>
            {process.env.REACT_APP_WHITE_LABEL !== 'true' && <LandingPageFeatureBox/>}
            {process.env.REACT_APP_WHITE_LABEL !== 'true' && <Spacer pixels={150}/>}
            {process.env.REACT_APP_WHITE_LABEL !== 'true' && <LandingPageMarketplaceImg/>}
            {process.env.REACT_APP_WHITE_LABEL !== 'true' && <Spacer pixels={150}/>}
            <LandingPageContactInfo/>
        </div>

        {/* Right Side*/}
        <div
            className='landing-page-right-div'
            css={`
                background-color: ${palette.secondary.sat};
                width: 40%;
                position: relative;
                min-width: 350px;
            `}
        >
            {/* Create an Account Container */}
            <div
                css={`
                    position: fixed;
                    width: 40%;
                    height: 100%;
                    min-width: 325px;
                    max-width: 560px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `}
            >
                <LoginForm/>
            </div>
        </div>
    </div>;
}

LandingPageRoot.defaultProps = {
}