// Setup.1. Core
import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import { palette } from '../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { capitalizeName } from './helpers';
interface LandingPageFeatureListProps {
    mobile?: boolean;
}
// Root
export const LandingPageFeatureList = (props: LandingPageFeatureListProps) => {
    const customerName = capitalizeName(process.env.REACT_APP_WHITE_LABEL_CUSTOMER)
    const toDoList = [
        {
            header: "Connect with carriers",
            body: "Connect with more carriers outside of your usual network"
        }, 
        {
            header: "Unlimited census uploads",
            body: "Upload unlimited submissions to multiple MGU's and carriers"
        },
        {
            header: "Receive instant decisions",
            body: "Receive instant quotable decisions on every group"
        },
        {
            header: "Avoid using IHQ's and past claims",
            body: "Evaluate groups without prior data"
        },
        {
            header: "Bind more business",
            body: "Bind business without the need for past claims data "
        },
    ]
    const whiteLabelToDoList = [
        {
            header: "Connect with underwriters directly",
            body: "Use the portals communication features to chat with Radion directly"
        },
        {
            header: "Receive quotes faster",
            body: "RFPs that come through the portal have an average response time of under 3 business days"
        },
        {
            header: "See all of your quotes in one place",
            body: "The seamless user interface keeps a clear record of all past and outstanding RFPs"
        },
    ]
  // Root.999. Render
  const array = process.env.REACT_APP_WHITE_LABEL == 'true' ? whiteLabelToDoList : toDoList 
    return <div
        className='featureListDiv'
        css={`
            padding: 0;
            @media (max-width: 850px) {
                padding: 45px 0;
                background-color: ${palette.secondary.light50};
            }
        `}
    >
        <header
            css={`
                margin: 0 60px;
                font-size: 4vw;
                font-weight: 600;
                line-height: 5vw;
                letter-spacing: -2.4px;
                color: ${palette.secondary.dark};
                text-align: left;

                @media (max-width: 850px) {
                    margin: 0 35px;
                    font-size: 24px;
                    line-height: 32px;
                    letter-spacing: -0.3px;
                    text-align: center;
                }
                @media (min-width: 1400px) {
                    font-size: 56px;
                    line-height: 68px;
                }
            `} 
        >
            {process.env.REACT_APP_WHITE_LABEL == 'true' ? `By signing up with ${customerName}, you can:` : "By using Marketplace, you'll be able to:"}
        </header>

        {
            array.map(item => {
                return <div
                    css={`
                        margin: 0 60px;
                        display: flex;
                        align-items: flex-start;
                        color: ${palette.secondary.dark};
                        line-height: 30px;
                        letter-spacing: -0.55px;
                        margin-top: 55px;
                        @media (max-width: 850px) {
                            margin: 0 35px;
                            line-height: 20.33px;
                            letter-spacing: -0.37px;
                            margin-top: 32px;
                        }
                    `}
                >
                    <FontAwesomeIcon 
                        className='check-bulletpoint'
                        css={`
                            color: ${palette.primary.main};
                            font-size: 22px;
                            margin-top: 4px;
                            margin-right: 12px;
                            @media (max-width: 850px) {
                                margin-top: 0;
                            }
                        `} 
                        icon={faCheckCircle}
                    />
                    <div
                        css={`
                            width: 350px;
                        `}
                    >
                        <div
                            css={`
                                font-size: 22px;
                                font-weight: 700;
                                @media (max-width: 850px) {
                                    font-size: 14px;
                                }
                            `}
                        >
                            {item.header}
                        </div>
                        <div
                            css={`
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 26px;
                                letter-spacing: -0.3px;
                                @media (max-width: 850px) {
                                    font-size: 14px;
                                    line-height: 20.33px;
                                    letter-spacing: -0.37px;
                                }
                            `}
                        >
                            {item.body}
                        </div>
                    </div>
                </div>
            })
        }

    </div>
}

LandingPageFeatureList.defaultProps = {
}