import {
  RESET_USER,
  SET_PASSWORD,
  SET_PREPOPULATE,
  SET_REFERRER_LOGO,
  SET_SNACK,
  SET_USER,
  SET_USER_FINISHED,
  TOGGLE_TERMS_AND_CONDITIONS_AGREED,
  SHIFT_SNACKS,
  SET_IS_WHITE_LABEL
} from "./actionTypes";
import {brokerFormSteps as formSteps} from '../lib/brokerFormSteps';

import pendoUpdateOptions from '../lib/pendoUpdateOptions';

const initialState = {
  isWhitelabel: false,
  snack: {},
  snacks: [],
  brokerFormSteps:formSteps,
  prepopulate: false,
  referrerLogo: '',

  user: {
    email: '',
    emailVerified: false,
    accountType: '',
    businessInformation: {
      brokerageName: '',
      headquartersLocation: '',
      website: '',
      stoplossGroupsPerYear: '',
      states: [],
      businessName: '',
      joinMarketplace: '',
    },
    referrer: null,
    tierPlan: 'Basic',
    account: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      phoneNumberValid: false,
      role: '',
      contactEmail: '',
      contactEmailValid: false,
    },
    finished: false,
  },

  termsAndConditionsAgreed: false,
  password: {
    password: '',
    confirmPassword: '',
    passwordValid: false,
    passwordValidMessage: '',
  },

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_WHITE_LABEL:
    {
      const newFormSteps = action.payload == "true" ? formSteps.filter(item => item.isWhiteLabel) : formSteps;
      return {
        ...initialState,
        isWhitelabel: Boolean(action.payload),
        brokerFormSteps: newFormSteps,
        ...(action.payload == "true" && { user: { ...initialState.user, accountType: "Broker" } })
      };
    }

    case RESET_USER:
    {
      return {
        ...initialState,
        referrerLogo: state.referrerLogo,
      };
    }

    // Prepopulate (PreP)
    case SET_PREPOPULATE:
    {

      // PreP.1. Set the User
      let user = {
        ...state.user,
        ...action.payload,
      };

      // PreP.2. Update Pendo
      pendoUpdateOptions(user);

      // PreP.999. Return State Object
      return {
        ...state,
        prepopulate: true,
        user,
      };
    }

    case SET_REFERRER_LOGO:
    {
      return {
        ...state,
        referrerLogo: action.payload,
      };
    }

    case SHIFT_SNACKS: {
      const updatedArray = [...state.snacks]
      const index = updatedArray.findIndex(d => d.message === action.payload);
      if(index > -1) {
        updatedArray.splice(index, 1);
      }
      return {
        ...state,
        snacks: updatedArray
      };
      ;
    }

    case SET_SNACK: {
      const snack = prepareSnack(state.snacks, action.payload);
      if (snack) {
        return {...state, snacks: [...state.snacks, snack]};
      } else {
        return {...state};
      }
    }

    case SET_USER:
    {
      let user = {
        ...state.user,
        ...action.payload,
      };

      pendoUpdateOptions(user);

      return {
        ...state,
        user,
      };
    }

    case SET_USER_FINISHED:
    {
      return {
        ...state,
        user: {
          ...initialState.user,
          finished: true,
        },
      };
    }

    case SET_PASSWORD:
    {
      return {
        ...state,
        password: {
          ...state.password,
          ...action.payload,
        },
      };
    }

    case TOGGLE_TERMS_AND_CONDITIONS_AGREED:
    {
      return {
        ...state,
        termsAndConditionsAgreed: !state.termsAndConditionsAgreed,
      };
    }

    default:
      return state;
  }
};

export default reducer;
export {
  initialState
};

function prepareSnack(state, snack) {
  snack.key = new Date().getTime();

  if (state.length > 0) {
    for (let i = 0; i < state.length; i++) {
      if (state[i].message.toString() === snack.message.toString()) {
        return null;
      }
    }
  }

  return snack;
}
