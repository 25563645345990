import React from 'react';
import AccountSetup from './AccountSetup';
import AccountType from './AccountType';
import BusinessInformation from './BusinessInformation';
import CreateAnAccount from './CreateAnAccount';
import CreateBrokerAccount from './CreateBrokerAccount';
import Done from './Done';
import PersonToContact from './PersonToContact';
import PersonToContactEnterprise from './PersonToContactEnterprise';
import TierPlan from './TierPlan';
import VerifyEmail from './VerifyEmail';

import history from '../lib/history';
import {
  Router,
  Switch,
  Redirect,
  Route,
  withRouter,
} from "react-router-dom";
import { LandingPageRoot } from './LandingPageRoot';
import { Communication } from './Communication';
import { TeamInvite } from './TeamInvite';
import CreateNewBrokerConnection from './CreateNewBrokerConnection';
import BrokerAlreadyConneced from './BrokerAlreadyConneced';

class AppContainer extends React.Component {

  render()
  {
    return <Router
      history={history}
    >
      <Switch>
        <Route
          exact
          path="/"
        >
          <Redirect
            to={{
              pathname: "/create-an-account",
              search: this.props.location.search,
             }}
          />
        </Route>
        <Route path="/create-an-account">
            <LandingPageRoot/>
        </Route>
        <Route path="/verify-email">
          <VerifyEmail />
        </Route>
        <Route path="/account-type">
          <AccountType />
        </Route>
        <Route path="/business-information">
          <BusinessInformation />
        </Route>
        <Route path="/tier-plan">
          <TierPlan />
        </Route>
        <Route path="/account-setup">
          <AccountSetup />
        </Route>
        <Route path="/communication">
          <Communication />
        </Route>
        <Route path="/team-invite">
          <TeamInvite />
        </Route>
        <Route path="/create-broker-account">
          <CreateBrokerAccount />
        </Route>
        <Route path="/create-broker-connection">
          <CreateNewBrokerConnection/>
        </Route>
        <Route path="/broker-connected">
          <BrokerAlreadyConneced/>
        </Route>
        <Route path="/person-to-contact">
          <PersonToContact />
        </Route>
        <Route path="/person-to-contact-enterprise">
          <PersonToContactEnterprise />
        </Route>
        <Route path="/done">
          <Done />
        </Route>
        <Route path="*">
          <Redirect to="/create-an-account" />
        </Route>
      </Switch>
    </Router>;
  }
}

export default withRouter(AppContainer);
