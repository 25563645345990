// Setup.1. Core
import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import { palette } from '../theme';
import {links} from '../assets/links';
import logo from "../assets/logo_appcontainer.svg";

interface LandingPageContactInfoProps {
    mobile?: boolean;
}
// Root
export const LandingPageContactInfo = (props: LandingPageContactInfoProps) => {

  // Root.999. Render
    return <div
        className='contactInfoDiv'
        css={`
            padding: 0;
            @media (max-width: 850px) {
                padding: 45px 0;
                background-color: ${palette.secondary.light50};
            }
        `}
    >
        <header
            css={`
                margin: 0 60px;
                font-size: 24px;
                line-height: 30px;
                letter-spacing: -0.6px;
                font-weight: 800;
                color: ${palette.secondary.dark};
                text-align: left;
                @media (max-width: 850px) {
                    margin: 0 20px;
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.3px;
                    font-weight: 700;
                    text-align: center;
                }
            `}
        >
            {process.env.REACT_APP_WHITE_LABEL == 'true' && 
                "Have questions?"
            }
            {process.env.REACT_APP_WHITE_LABEL != 'true' &&
                "Have questions about how Marketplace can help you?"
            }
        </header>

        <div
            css={`
                margin: 0 60px;
                font-size: 16px;
                line-height: 26px;
                letter-spacing: -0.2px;
                font-weight: 500;
                margin-top: 8px;
                color: ${palette.secondary.dark};
                text-align: left;
                @media (max-width: 850px) {
                    margin: 0 25px;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.11px;
                    font-weight: 400;
                    margin-top: 16px;
                    text-align: center;
                }
            `}
        >
            Contact a member of our team at 
            <a 
                className='email-link'
                css={`
                    margin-left: 7px;
                    color: #00ADF8;
                `}
                href={process.env.REACT_APP_WHITE_LABEL == 'true' ?`mailto:${links.support.link}` : `mailto:support@verikai.com`}
            >
                {process.env.REACT_APP_WHITE_LABEL == 'true' ?`${links.support.link}` : `support@verikai.com`}
            </a>
            <div>
            Learn more at 
            <a 
                className='website-link'
                css={`
                    margin-left: 7px;
                    color: #00ADF8;
                `}
                href={process.env.REACT_APP_WHITE_LABEL == 'true' ? `${links.website.link}` : `https://portal.verikai.com/`}
            >
                {process.env.REACT_APP_WHITE_LABEL == 'true' ? `${links.website.link}` : `https://portal.verikai.com/`}
            </a>
            </div>
        </div>

        <div
            css={`
                margin: 0 60px;
                margin-top: 80px;
                padding-top: 35px;
                border-top: 2px solid ${palette.secondary.sat17};
                font-size: 14px;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: -0.2px;
                color: ${palette.secondary.dark};
                width: calc(100% - 120px);
                text-align: left;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                @media (max-width: 850px) {
                    margin: 0px;
                    margin-top: 60px;
                    border-top: 1px solid ${palette.secondary.sat17};
                    font-size: 12px;
                    width: 100%;
                    text-align: center;
                    justify-content: space-evenly;
                }
            `}
        >
            <div>{`Copyright © ${new Date().getFullYear()} Verikai. All Rights reserved.`}</div>
            <div css={`display:flex; align-items: center;`}>Powered by <img css={`height: 24px;`} src={logo}/></div>
        </div>
    </div>
}

LandingPageContactInfo.defaultProps = {
}