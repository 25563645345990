import React from 'react';
import 'styled-components/macro';

import Button from '@material-ui/core/Button';
import Display from './Display';
import { connect } from 'react-redux';
import { palette } from '../theme';
import resetUser from '../redux/actions/resetUser';
import setUserFinished from '../redux/actions/setUserFinished';
import { withRouter } from "react-router-dom";
import verikai_v from "../assets/GroupVeriakai-V.png";
import paper from "../assets/Paper.png";
import blankPaper from "../assets/blankPaper.png";
import {links} from "../assets/links";

const mapDispatchToProps = dispatch => ({
  resetUser: () => dispatch(resetUser()),
  setUserFinished: () => dispatch(setUserFinished()),
});

const mapStateToProps = state => ({
  user: state.user,
  password: state.password,
  termsAndConditionsAgreed: state.termsAndConditionsAgreed,
});

class CreateBrokerAccount extends React.Component
{

  componentDidMount()
  {
    this.props.resetUser();
  }

  render()
  {
    return <Display
      signedUp={true}
      outerTop={<div
      css={`
          background-color: ${palette.secondary.light};
          border-radius: 10px;
          position: relative;
        `}
      >

        <div
        className='topDisplaySection'
          css={`
              background-color: ${palette.secondary.light};
              position: relative;
              display flex;
              flex-direction: column;
              align-items: center;
            `}
        >
          {process.env.REACT_APP_WHITE_LABEL !== 'true' && <img
            css={`
              position: absolute;
              right: 0px;
              top:-100px;
              height: calc(100% + 100px);
            `}
            src={verikai_v}
          />}

          <img
            css={`
              position: absolute;
              left: 33%;
              top: 85%;
              height: 70px;
              transform: rotate(40deg);
              filter: blur(3px);
            `}
            src={paper}
          />

          <img
            css={`
              position: absolute;
              left: 38%;
              top: -50px;
              height: 40px;
              transform: rotate(45deg);
            `}
            src={paper}
          />

          <img
            css={`
              position: absolute;
              left: 4%;
              top: 80%;
              height: 70px;
            `}
            src={paper}
          />

          <img
            css={`
              position: absolute;
              left: 68%;
              top: 80%;
              height: 20px;
            `}
            src={blankPaper}
          />



          <div
          className='congrats-banner'
            css={`
              font-family: Mulish;
              font-size: 32px;
              font-style: normal;
              font-weight: 700;
              line-height: 42px;
              letter-spacing: 0em;
              text-align: center;
              color: ${palette.secondary.dark};
              background-color: ${palette.secondary.light};
              padding-top: 40px;
              margin-top: 31.5px;
              margin-bottom: 16px;
            `}
          >
            Congratulations, your account is setup!
          </div>
        <div
          css={`
            font-family: Mulish;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            color: ${palette.secondary.sat};
            padding-left: 45px;
            padding-right: 45px;
            margin-bottom: 69px;
          `}
        >
          Your account login information has been emailed to you.
        </div>
        </div>


        <div
        className='done-btn-holder'
          css={`
            display: flex;
            justify-content: end;
            background-color: ${palette.secondary.light25};
            border-radius: 0px 0px 10px 10px;
            height: 91px;
            @media (max-width: 850px) {
              background-color: ${palette.secondary.light};
            }
          `}
        >
          <Button
          className='done-btn'
            css={`
              background-color: ${palette.primary.main};
              box-shadow: none;
              margin-top: 7px;
              text-transform: none;
              font-family: Mulish;
              font-size: 15px;
              font-style: normal;
              font-weight: 800;
              line-height: 17px;
              letter-spacing: 0em;
              color: #fff;
              padding-top: 14.5px;
              padding-bottom: 14.5px;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                box-shadow: none;
                background-color: ${palette.primary.main};
              }
              width: 172px;
              margin: 25px 20px 20px 15px;
              @media (max-width: 850px) {
                width: 100%;
              }
            `}
            variant="contained"
            href={process.env.REACT_APP_WHITE_LABEL == "true" ? links.portal.link : "https://portal.verikai.com"}
          >
            Start using portal
          </Button>
        </div>
      </div>}
    />;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateBrokerAccount));
