// Setup.1. Core
import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import verikaiLogo from "../assets/logo_login.svg";
import logo from "../assets/logos/logo.svg";
import { palette } from '../theme';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useHistory } from "react-router-dom";

interface LogosProps {
}

// Root
export const Logos = (props: LogosProps) => {
    const referrerLogo = useSelector((state: RootStateOrAny) => state.referrerLogo);

    
  // Root.999. Render
    return <div
        css={`
            margin: 0 60px;
            display: flex;
            justify-content: flex-start;
            @media (max-width: 850px) {
                margin: 0px;
                justify-content: center;
            }
        `}
    >
        <img css={`height: 40px`} src={process.env.REACT_APP_WHITE_LABEL == 'true' ? logo : verikaiLogo} />
        {   referrerLogo &&
            <img 
                css={`
                    height: 40px; 
                    border-left: 1px solid ${palette.secondary.dark}; 
                    margin-left: 15px; 
                    padding-left: 15px
                `} 
                src={referrerLogo} 
            />
        }
    </div>
}

Logos.defaultProps = {
}