import React from 'react';
import 'styled-components/macro';

import Button from '@material-ui/core/Button';
import Display from './Display';
import Steps from './Steps';
import TextField from './verikai/TextFieldBase';

import API from '../lib/API';

import { connect } from 'react-redux';
import brokerFormSteps from '../lib/underwriterFormSteps';
import emailValidTest from '../lib/emailValidTest';
import formatPhoneNumber from '../lib/formatPhoneNumber';
import { palette } from '../theme';
import setUser from '../redux/actions/setUser';
import { withRouter } from "react-router-dom";

const mapDispatchToProps = dispatch => ({
  setUser: v => dispatch(setUser(v)),
});

const mapStateToProps = state => ({
  user: state.user,
});

class PersonToContactEnterprise extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      saving: false,
    };
  }

  componentDidMount()
  {
    const b = this.props.user.businessInformation;
    if(!this.props.user.email || !this.props.user.emailVerified)
    {
      this.props.history.push('/create-account');
    }
    else if(!this.props.user.accountType)
    {
      this.props.history.push('/account-type');
    }
    else if
    (
      !b.brokerageName
    )
    {
      this.props.history.push('/business-information');
    }
    // else if
    // (
    //   !this.props.user.tierPlan
    // )
    // {
    //   this.props.history.push('/tier-plan');
    // }
  }

  handleChangeInput(e)
  {
    let value = e.target.value;
    let valueValid = undefined;
    let valueValidName = undefined;
    let name = e.target.name;
    if(name === 'phoneNumber')
    {
      valueValid = false;
      valueValidName = 'phoneNumberValid';
      value = formatPhoneNumber(value);
      if(value.length === 12)
      {
        valueValid = true;
      }
    }

    if(name === 'contactEmail')
    {
      valueValid = emailValidTest(value);
      valueValidName = 'contactEmailValid';
    }

    this.props.setUser({
      account: {
        ...this.props.user.account,
        [e.target.name]: value,
        ...(valueValidName !== undefined && {
          [valueValidName]: valueValid,
        }),
      },
    });
  }

  handleBack()
  {
    this.props.history.push('/business-information');
  }

  async handleFinish()
  {
    this.setState({
      saving: true,
    });

    const a = this.props.user.account;
    const b = this.props.user.businessInformation;

    let api = new API();
    let result = await api.post({
      path: '/save-broker',
      body: {
        brokerage_name: b.brokerageName,
        headquarters_location: b.headquartersLocation,
        website: b.website,
        groups_per_year: b.stoplossGroupsPerYear,
        states: b.states.join(','),
        first_name: a.firstName,
        last_name: a.lastName,
        phone_number: a.phoneNumber,
        email: this.props.user.email,
        contact_email: a.contactEmail,
      },
    });
    if(result !== undefined)
    {
      this.props.history.push('/done');
    }
    else
    {
      this.setState({
        saving: false,
      });
    }
  }

  render()
  {
    const a = this.props.user.account;
    const finishDisabled = (
      !a.firstName
      || !a.lastName
      || !a.phoneNumber
      || !a.phoneNumberValid
      || !a.contactEmail
      || !a.contactEmailValid
    );

    return <Display
      title="We're excited to get you setup with our Enterprise tier!"
      outerTop={<Steps
        currentStepNumber={3}
        steps={[
          brokerFormSteps[0],
          brokerFormSteps[1],
          {label: 'Person to contact'},
        ]}
      />}
      outerBottom={<div
        css={`
          display: flex;
          justify-content: space-between;
          margin-top: 32px;
        `}
      >
        <Button
          css={`
            color: ${palette.link.blue};
            text-transform: none;
            font-family: Mulish;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 0em;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 5px;
          `}
          onClick={this.handleBack.bind(this)}
        >
          Back
        </Button>

        <Button
          css={`
            background-color: ${palette.primary.main};
            box-shadow: none;
            margin-top: 7px;
            text-transform: none;
            font-family: Mulish;
            font-size: 15px;
            font-style: normal;
            font-weight: 800;
            line-height: 17px;
            letter-spacing: 0em;
            color: #fff;
            padding-top: 14.5px;
            padding-bottom: 14.5px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              box-shadow: none;
              background-color: ${palette.primary.main};
            }
            margin-bottom: 17px;
          `}
          variant="contained"
          type="submit"
          disabled={finishDisabled || this.state.saving}
          onClick={this.handleFinish.bind(this)}
        >
          {
            this.state.saving
              ? "Saving..."
              : "Finish"
          }
        </Button>
      </div>}
    >
      {/* Subtitle */}
      <div
        css={`
          font-family: Mulish;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: left;
          color: ${palette.secondary.dark};
          display: flex;
          margin-bottom: 32px;
        `}
      >
        Please fill out the fields below. A sales team member will contact you to step you through completion of the process.
      </div>

      <div
        css={`
          display: flex;
          justify-content: center;
        `}
      >
        <TextField
          label="First name"
          fullWidth
          onChange={this.handleChangeInput.bind(this)}
          name="firstName"
          value={this.props.user.account.firstName}
          disabled={this.state.saving}
        />
        <div css={`width: 20px;`} />
        <TextField
          label="Last name"
          fullWidth
          onChange={this.handleChangeInput.bind(this)}
          name="lastName"
          value={this.props.user.account.lastName}
          disabled={this.state.saving}
        />
      </div>
      <div css={`height: 11px`}/>

      <div
        css={`
          display: flex;
          justify-content: center;
        `}
      >
        <TextField
          label="Phone number"
          fullWidth
          onChange={this.handleChangeInput.bind(this)}
          name="phoneNumber"
          value={this.props.user.account.phoneNumber}
          disabled={this.state.saving}
        />
        <div css={`width: 20px;`} />
        <div css={`width: 100%;`}/>
      </div>
      <div css={`height: 11px`}/>

      <TextField
        label="Contact Email"
        fullWidth
        onChange={this.handleChangeInput.bind(this)}
        name="contactEmail"
        value={this.props.user.account.contactEmail}
        error={!this.props.user.account.contactEmailValid}
        helperText={
          !this.props.user.account.contactEmailValid && this.props.user.account.contactEmail
            ? 'You must enter a valid email.'
            : ''
        }
        disabled={this.state.saving}
      />

    </Display>;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonToContactEnterprise));
