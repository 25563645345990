import {
    SET_IS_WHITE_LABEL,
  } from "../actionTypes";
  
  export default function setIsWhiteLabel(v) {
    return {
      type: SET_IS_WHITE_LABEL,
      payload: v,
    };
  }
  