import {
  applyMiddleware,
  createStore,
} from 'redux';
import {
  persistStore,
  persistReducer,
  //createMigrate ,
} from 'redux-persist';
import createEncryptor from '../lib/redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import reducer from './reducer';

const encryptor = createEncryptor({
  secretKey: 'GVbtAMToVMcvjMadMWf7g6a*',
  onError: function(error) {
    //console.log(error);
  }
});

const persistConfig = {
  key: 'root',
  storage,
  version: 0,
  transforms: [encryptor],
  whitelist: [
    'user',
    'prepopulate',
    'referrerLogo',
    'isWhitelabel'
  ],
};

const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);

export {
  store,
  persistor,
};
