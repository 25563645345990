import React from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion } from "framer-motion";
import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/styles/withStyles';

const styles = (theme) => ({
  helperText: {
    ...theme.fonts.labelSmallRegularMobile,
  },
  helperTextError: {
    color: theme.palette.error.main,
  },
  helperTextWarning: {
    color: theme.palette.warning.main,
  },
  root: {
    height: '17px',
  },
});

class VerikaiHelperText extends React.Component {

  render()
  {
    const {
      children,
      classes,
      error,
      open,
      mobile,
      ...other
    } = this.props;

    return(<div
      {...other}
      className={classes.root}
    >
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ opacity: 0, y: -5, }}
            animate={{ opacity: 1, y: 0, }}
            exit={{ opacity: 0, y: -5, }}
            transition={{
              type: "spring",
              damping: 10,
              stiffness: 100
            }}
          >
            <Typography
              className={clsx(
                classes.helperText,
                error
                  ? mobile ?
                    classes.helperTextWarning
                    :classes.helperTextError
                  : null,
              )}
            >
              {children}
            </Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </div>);
  }
}

export default withStyles(styles)(VerikaiHelperText);
