import React from 'react';
import 'styled-components/macro';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faCheck,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { palette } from '../theme';

export default class Steps extends React.Component
{
  render()
  {
    return <div
      className='stepHolder'
      css={`
        display: flex;
        justify-content: center;
        margin-top: 6px;
        margin-bottom: 39px;

        @media (max-width: 850px) {
          background: ${palette.secondary.light50};
          padding: 16px 0px;
          margin: 0 30px;
          border-bottom: 1px solid ${palette.secondary.sat30};
        }
      `}
    >

      {/* Step Container */}
      <div
        css={`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >

        {this.props.steps.map((step, idx) => <>
          {/* Step Number Container */}
          <div
            className={this.props.currentStepNumber < idx + 1
              ? "step-number step-ahead"
              : this.props.currentStepNumber === idx + 1 
              ? "step-number step-current" : "step-number step-complete"}
            css={`
              height: 20px;
              width: 20px;
              border-radius: 100px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: ${
                this.props.currentStepNumber < idx + 1
                  ? palette.secondary.sat30
                  : palette.secondary.light25
              };
              background-color: ${
                this.props.currentStepNumber === idx + 1
                  ? palette.secondary.dark
                : this.props.currentStepNumber >= idx + 1
                  ? palette.primary.main
                  : 'transparent'
              };
              font-family: Mulish;
              font-size: 10px;
              font-style: normal;
              font-weight: 800;
              line-height: 15px;
              letter-spacing: 0em;
              margin-right: 8px;
              ${
                this.props.currentStepNumber < idx + 1
                  ? `border: 1px solid ${palette.secondary.sat30};`
                  : ''
              }
            `}
          >
            {
              this.props.currentStepNumber > idx + 1
                ? <FontAwesomeIcon
                  className='check-icon'
                  icon={faCheck}
                />
                : idx + 1
            }
          </div>

          {/* Step Label */}
          <div
            css={`
              font-family: Mulish;
              font-size: 14px;
              font-style: normal;
              font-weight: 800;
              line-height: 21px;
              letter-spacing: 0em;
              text-align: left;
              color: ${
                this.props.currentStepNumber === idx + 1
                  ? palette.secondary.dark
                : this.props.currentStepNumber >= idx + 1
                  ? palette.primary.main
                  : palette.secondary.sat30
              };
              @media (max-width: 850px) {
                display: none;
              }
            `}
          >
            {step.label}
          </div>

          {/* Arrow */}
          {
            idx !== this.props.steps.length - 1
              ? <FontAwesomeIcon
                css={`
                  color: ${palette.secondary.sat30};
                  font-size: 12px;
                  margin-right: 22px;
                  margin-left: 22px;
                `}
                icon={faChevronRight}
              />
              : null
          }
        </>)}
      </div>
    </div>;
  }
}
