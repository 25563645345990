import axios from 'axios';
import { Buffer } from 'buffer';

interface Params {
  assetEndpoint?: string;
  cacheKey?: string | null;
  route: string | null | undefined;
};

export const fetchTermsAndConditions = async({
  assetEndpoint="https://verikai-assets.s3.us-west-2.amazonaws.com",
  cacheKey=null,
  route=null,
}: Params) => {
  let response: string | undefined = undefined;

  if(route === null || route === undefined || route === '')
  {
    return response;
  }

  let endpoint = `${assetEndpoint}/${route}`;
  cacheKey = cacheKey === null
    ? endpoint
    : cacheKey;

  try {
    let pdf = await axios.get(
      `${assetEndpoint}/${route}`,
      {
        responseType: 'arraybuffer',
        params: {
          t: new Date().getTime()
        },
      }
    );
      let b64: string = Buffer.from(pdf.data, 'binary').toString('base64');
      let contentType: string = pdf.headers['content-type'];
      let pdfStream = `data:${contentType};base64,${b64}`;
      return pdfStream;        
  }
  catch(err) {
    return response;
  }

};
