export const RESET_USER = "RESET_USER";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_PREPOPULATE = "SET_PREPOPULATE";
export const SET_REFERRER_LOGO = "SET_REFERRER_LOGO";
export const SET_SNACK = "SET_SNACK";
export const SET_USER = "SET_USER";
export const SET_USER_FINISHED = "SET_USER_FINISHED";
export const TOGGLE_TERMS_AND_CONDITIONS_AGREED = "TOGGLE_TERMS_AND_CONDITIONS_AGREED";
export const SHIFT_SNACKS = "SHIFT_SNACKS";
export const SET_IS_WHITE_LABEL = "SET_IS_WHITE_LABEL";
