import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/styles/withStyles';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light50,
    width: '100vw',
    height: '100vh',
    paddingTop: '15vh',
    textAlign: 'center',
  },
});

class VerikaiProgressPage extends React.Component {

  render() {
    const {
      classes,
      disabled,
      loading,
      loadingLabel,
      variant,
      ...other
    } = this.props;

    return <div
      {...other}
      className={classes.root}
    >
      <CircularProgress
        color="secondary"
      />
    </div>;
  }
}

export default withStyles(styles)(VerikaiProgressPage);
