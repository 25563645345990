// Setup.1. Core
import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import { palette } from '../theme';
import { capitalizeName } from './helpers';


interface LandingPageJoinMarketplaceProps {
    mobile?: boolean;
}
// Root
export const LandingPageJoinMarketplace = (props: LandingPageJoinMarketplaceProps) => {
    const customerName = capitalizeName(process.env.REACT_APP_WHITE_LABEL_CUSTOMER)
  // Root.999. Render
    return <>
        <header
            className='joinHeader'
            css={`
                margin: 0 60px;
                font-size: 5vw;
                font-weight: 700;
                line-height: 6vw;
                letter-spacing: -3px;
                color: ${palette.secondary.dark};
                text-align: left;
                @media (max-width: 850px) {
                    margin: 0 10px;
                    font-size: 32px;
                    line-height: 38px;
                    letter-spacing: -1.13px;
                    text-align: center;
                }
                @media (min-width: 1200px) {
                    font-size: 72px;
                    line-height: 80px;
                }
            `} 
        >
            {`Join the ${process.env.REACT_APP_WHITE_LABEL == 'true' ? `${customerName} portal` : "Verikai Marketplace"}`}
        </header>

        <summary
            css={`
                margin: 0 60px;
                margin-top: 26px;
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: -0.3px;
                text-align: left;
                color: ${palette.secondary.dark};
                @media (max-width: 850px) {
                    margin: 0 10px;
                    margin-top: 24px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: -0.11px;
                    text-align: center;
                }
            `} 
        >
            { process.env.REACT_APP_WHITE_LABEL == 'true' ? `Create your brokerage's account to easily submit RFPs to ${customerName}.`: "Marketplace is the future of insurance negotiations - bringing brokers, carriers, and MGU's together from across the industry. "}
        </summary>
    </>
}

LandingPageJoinMarketplace.defaultProps = {
}