// Setup.1. Core
import 'styled-components/macro';
import { palette } from '../theme';
import { useSelector, RootStateOrAny } from 'react-redux';
import { Button } from '@material-ui/core';
import Checkbox from './verikai/Checkbox';
import React, { useEffect, useState } from 'react';
import Display from './Display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faClose } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import brokerFormSteps from '../lib/brokerFormSteps';
import Steps from './Steps';
import API from '../lib/API';
import { BusinessCard } from './BuisnessCard';
import { Table } from './Table';

interface Customer {
  customer_id: number, 
  customer_name: string, 
  customer_description: string | null, 
  customer_logo_file_key: string | null,
  customer_headquarters_location: string | null,
  customer_website: string | null
}
// Root
export const Communication = () => {
    const history = useHistory();

    const [selectedUnderwriters, selectUnderwriter] = useState({});
    const [underwriterList, setUnderwriter] = useState<Array<Customer>>([]);
    const referrer = useSelector((state: RootStateOrAny) => state.user.referrer);
    const email = useSelector((state: RootStateOrAny) => state.user.email);
    const referrerLogo = useSelector((state: RootStateOrAny) => state.referrerLogo);

    useEffect(() => {
      getUnderwriters();
    }, [])


    const getUnderwriters = async () => {
      let api = new API();
      let result: Array<Customer> = [];
      try {
        result = await api.get({
            path: `/underwriter`,
        });
      } catch(err) {
        console.log(err);
      }

      if(result.length > 0) {
        setUnderwriter(result);
      } else {
        setUnderwriter([]);
      }
      selectAll(result, referrer);
    }

    const selectAll = (array = underwriterList, logoKey = null) => {
      const placeholder: {[key: number]: boolean} = {}
      array.forEach(item => {
          if(logoKey) {
            placeholder[item.customer_id] = item.customer_logo_file_key == logoKey;
          } else {
            placeholder[item.customer_id] = true;
          }
      })
      selectUnderwriter({...selectedUnderwriters, ...placeholder});
    }

    const updateRelationship = async (underwriters = selectedUnderwriters) => {
      let api = new API();
      try {
        await api.post({
            path: `/update-relationship`,
            body: {
              underwriters
            },
        });
      } catch(err) {
        console.log(err);
      }
    }

    const handleSelectUnderwriter = (item: Customer, value: boolean) => {
      selectUnderwriter({...selectedUnderwriters, [item.customer_id]: value });
    }

    const handleNext = () => {
        updateRelationship();
        history.push('/team-invite');
    }

    // Root.999. Render
    return <Display
      title="Who would you like to communicate with in the Markletplace?"
      outerTop={<Steps
        currentStepNumber={2}
        steps={brokerFormSteps}
      />}
      outerBottom={<div
        css={`
          display: flex;
          justify-content: flex-end;
          margin-top: 32px;
          @media (max-width: 850px) {
            flex-direction: column-reverse;
            padding: 0 30px;
            margin-top: 0px;
            margin-bottom: 16px;
          }
        `}
      >
        <Button
          css={`
            background-color: ${palette.primary.main};
            box-shadow: none;
            margin-top: 7px;
            text-transform: none;
            font-family: Mulish;
            font-size: 15px;
            font-style: normal;
            font-weight: 800;
            line-height: 17px;
            letter-spacing: 0em;
            color: #fff;
            padding-top: 14.5px;
            padding-bottom: 14.5px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              box-shadow: none;
              background-color: ${palette.primary.main};
            }
            margin-bottom: 17px;
          `}
          variant="contained"
          type="submit"
        //   disabled={!this.props.user.emailVerified || !this.props.user.accountType}
          onClick={() => handleNext()}
        >
          <div>
            Next
          </div>
          <FontAwesomeIcon
            css={`
              margin-left: 10px;
              font-size: 12px;
              font-weight: 900;
            `}
            icon={faArrowRight}
          />
        </Button>
      </div>}
    >

      {/* Subtitle */}
      <div
        css={`
          font-family: Mulish;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: left;
          color: ${palette.secondary.sat};
          display: flex;
          margin-bottom: 8px;
        `}
      >
        Select the Underwriters that you are open to communicate with.
      </div>


      <div
        css={`
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 15.6px;
          text-align: left;
          color: ${palette.secondary.sat};
          margin-bottom: 32px;
        `}
      >
        You can update/change your communication settings when logged into Marketplace. 
        When logged in, this feature can be accessed by navigating to 
        <span css={`font-weight: 800; margin: 0 5px;`}>SETTINGS</span> 
        (located in the upper right corner of the Marketplace interface).
      </div>

        {/* Table */}
        <Table 
            headerItems={[
                <Button
                    css={`
                        color: ${palette.link.blue};
                        background-color: ${palette.link.blue10};
                        text-transform: none;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 13.8px;
                        padding: 4px 8px;
                        border-radius: 4px;
                        width: 67px;
                        height: 22px;
                    `}
                onClick={() => selectAll()}
                >
                    Select all
                </Button>,
                "Underwriter",
                "Description"
            ]}
            headerKeys={["Checkbox", "Underwriter", "Description"]}
            columnProps={
                [
                    {
                        "width":"90px", 
                        "text-align":"center",
                        "weight": 100
                    }, 
                    {
                        "width":"175px", 
                        "text-align":"left",
                        "weight": 800
                    }, 
                    {
                        "width":"500px", 
                        "text-align":"left",
                        "weight": 400
                    }
            ]
            }
            rows={underwriterList.map((item, i) => {
                const isChecked = selectedUnderwriters[item.customer_id as keyof typeof selectedUnderwriters];
                let desc = item.customer_description?.substr(0, 100);
                desc = desc && desc.length < 100 ? desc : desc?.substr(0, Math.min(desc.length, desc.lastIndexOf(" ")));
                return { 
                    "Checkbox": <Checkbox
                        checked={isChecked}
                        onChange={() => handleSelectUnderwriter(item, !isChecked)}
                        cssProps={`
                        `}
                    />,
                    "Underwriter": item.customer_name,
                    "Description": <Description 
                        desc={desc} 
                        fullDesc={item.customer_description} 
                        location={item.customer_headquarters_location}
                        logo={item.customer_logo_file_key ? "logos/" + item.customer_logo_file_key : null}
                        name={item.customer_name}
                        website={item.customer_website}
                      />
                }
            })}
        />

    </Display>;
}

Communication.defaultProps = {
}


interface DescriptionProps {
  desc: string | undefined;
  fullDesc: string | null;
  location: string | null;
  logo: string | null;
  name: string;
  website: string | null;
}

const Description = (props: DescriptionProps) => {
  const [showFullDesc, toggleDesc] = useState(false);

  if(showFullDesc) {
    return <div
      css={`
          background: rgba(0,0,0,0.3);
          position: fixed;
          top: 0px;
          left: 0px;
          width: 100vw;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 100;
      `}
    >
      <div
        css={`
            padding-top: 15px;
            width: 50%;
            min-width: 450px;
            max-width: 700px;
            background-color: #FFFFFF;
            border-radius: 4px;
            color: ${palette.secondary.dark80};
        `}
      >
        <div 
          css={`
            width: 100%; 
            padding: 0 30px;
            cursor: pointer; 
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
          onClick={() => toggleDesc(false)}
        > 
          <div
          css={`
            font-size: 10px;
            font-style: normal;
            font-weight: 800;
            line-height: 11px;
            letter-spacing: 0.16em;
          `}
          >
            UNDERWRITER DESCRIPTION
          </div>
          
          <div>
            <FontAwesomeIcon css={`margin-right: 5px;`} icon={faClose}/> 
            close
          </div>
        </div>
        <BusinessCard 
          description={props.fullDesc}
          headquartersLocation={props.location}
          logoPath={props.logo}
          name={props.name}
          website={props.website}
        /> 
      </div>
  </div>
  }
  return <>
    {props.desc}
    {props.desc ? 
      <span 
        css={`
          margin-left: 5px; 
          text-decoration: underline; 
          color: ${palette.link.blue};
          cursor: pointer;
          font-weight: 700;
        `}
          onClick={() => toggleDesc(true)}
        >
          more
        </span> 
      : null}
  </>
}

