import React from 'react';
import 'styled-components/macro';

import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { palette } from '../theme';

export default class Tier extends React.Component
{
  render()
  {
    return <div>
      <div
        css={`
          background-color: ${
            this.props.recommended
              ? palette.primary.main
              : 'transparent'
          };
          height: 23px;
          width: 207px;
          font-family: Mulish;
          font-size: 10px;
          font-style: normal;
          font-weight: 800;
          line-height: 11px;
          letter-spacing: 0em;
          text-align: center;
          color: ${palette.secondary.light25};
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        {
          this.props.recommended
            ? 'Verikai recommends'
            : ''
        }
      </div>
      <div
        css={`
          background-color: ${
            this.props.recommended
              ? palette.primary.main25_2
              : palette.secondary.light25
          };
          border: ${
            this.props.recommended
              ? `2px solid ${palette.primary.main}`
              : `1px solid ${palette.secondary.sat17}`
          };
          width: 207px;
          height: 638px;
          padding-left: 12px;
          padding-right: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >

        {/* Title */}
        <div
          css={`
            margin-top: 24px;
            font-family: Mulish;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 33px;
            letter-spacing: 0em;
            color: ${palette.secondary.dark};
            margin-bottom: 19px;
            text-align: center;
          `}
        >
          {this.props.title}
        </div>

        {/* Description */}
        <div
          css={`
            height: 80px;
          `}
        >
          <div
            css={`
              font-family: Mulish;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: 0em;
              color: ${palette.secondary.dark};
              text-align: center;
            `}
          >
            {this.props.description}
          </div>
        </div>

        {
          this.props.costIsCustom
            ? <div
              css={`
                height: 48px;
                font-family: Mulish;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: center;
                color: ${palette.secondary.dark60};
                padding-top: 7px;
                text-align: center;
              `}
            >
              Custom pricing
            </div>
            : <>
              <div
                css={`
                  font-family: Mulish;
                  font-size: 22px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 33px;
                  letter-spacing: 0em;
                  text-align: center;
                  color: ${palette.secondary.dark};
                  height: 33px;
                `}
              >
                {this.props.costTitle}
              </div>

              <div
                css={`
                  height: 15px;
                  font-family: Mulish;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 15px;
                  letter-spacing: 0em;
                  text-align: center;
                  color: ${palette.secondary.sat};
                `}
              >
                {this.props.costSubtitle}
              </div>
            </>
        }

        {/* Select Button */}
        <Button
          css={`
            background-color: ${palette.primary.main};
            box-shadow: none;
            margin-top: 7px;
            text-transform: none;
            font-family: Mulish;
            font-size: 15px;
            font-style: normal;
            font-weight: 800;
            line-height: 17px;
            letter-spacing: 0em;
            color: #fff;
            padding-top: 14.5px;
            padding-bottom: 14.5px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              box-shadow: none;
              background-color: ${palette.primary.main};
            }
            margin-top: 19px;
            margin-bottom: 26px;
          `}
          variant="contained"
          onClick={() => this.props.onSelect(this.props.title)}
        >
          {
            this.props.selectLabel
              ? this.props.selectLabel
            : this.props.costIsCustom
              ? 'Contact sales'
              : 'Select'
          }
        </Button>

        <div
          css={`
            height: 1px;
            background-color: ${palette.secondary.sat50};
            width: 147px;
            text-align: center;
            margin-bottom: 19px;
          `}
        />

        {/* Feature List Title */}
        <div
          css={`
            margin-bottom: 19px;
            font-family: Mulish;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: center;
            color: ${palette.secondary.dark};
            width: 147px;
          `}
        >
          {this.props.featureListTitle}
        </div>

        {/* Feature List */}
        {
          this.props.featureList
            ? this.props.featureList.map((feature, idx) => <div
              css={`
                display: flex;
                justify-content: flex-start;
                width: 100%;
                padding-left: 18px;
                padding-right: 18px;
                ${
                  idx !== this.props.featureList.length - 1
                    ? 'margin-bottom: 19px;'
                    : ''
                }
              `}
            >
              <FontAwesomeIcon
                css={`
                  color: ${
                    feature.available
                      ? palette.primary.hover
                      : palette.secondary.sat30
                  };
                  font-size: 14px;
                  margin-right: 8px;
                  margin-top: 2px;
                `}
                icon={
                  feature.available
                    ? faCheck
                    : faTimes
                }
              />
              <div
                css={`
                  font-family: Mulish;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 17px;
                  letter-spacing: 0em;
                  text-align: left;
                  color: ${
                    feature.available
                      ? palette.secondary.dark
                      : palette.secondary.sat30
                  };
                `}
              >
                {feature.label}
              </div>
            </div>)
            : null
          }

      </div>
    </div>
  }
}
