import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { common } from "@material-ui/core/colors";

import { iterateJSStoCSS } from './lib/convertJSStoCSS';

import Mulish200 from './assets/Mulish/Mulish-ExtraLight.ttf';
import Mulish200Italic from './assets/Mulish/Mulish-ExtraLightItalic.ttf';
import Mulish300 from './assets/Mulish/Mulish-Light.ttf';
import Mulish300Italic from './assets/Mulish/Mulish-LightItalic.ttf';
import Mulish400 from './assets/Mulish/Mulish-Regular.ttf';
import Mulish400Italic from './assets/Mulish/Mulish-Italic.ttf';
import Mulish500 from './assets/Mulish/Mulish-Medium.ttf';
import Mulish500Italic from './assets/Mulish/Mulish-MediumItalic.ttf';
import Mulish600 from './assets/Mulish/Mulish-SemiBold.ttf';
import Mulish600Italic from './assets/Mulish/Mulish-SemiBoldItalic.ttf';
import Mulish700 from './assets/Mulish/Mulish-Bold.ttf';
import Mulish700Italic from './assets/Mulish/Mulish-BoldItalic.ttf';
import Mulish800 from './assets/Mulish/Mulish-ExtraBold.ttf';
import Mulish800Italic from './assets/Mulish/Mulish-ExtraBoldItalic.ttf';
import Mulish900 from './assets/Mulish/Mulish-Black.ttf';
import Mulish900Italic from './assets/Mulish/Mulish-BlackItalic.ttf';

import PridiBold from './assets/Pridi-Bold.ttf';
import PridiExtraLight from './assets/Pridi-ExtraLight.ttf';
import PridiLight from './assets/Pridi-Light.ttf';
import PridiMedium from './assets/Pridi-Medium.ttf';
import PridiRegular from './assets/Pridi-Regular.ttf';
import PridiSemiBold from './assets/Pridi-SemiBold.ttf';

const mulish200 = {
  fontFamily: 'Mulish',
  fontWeight: '200',
  fontStyle: 'normal',
  src: `url(${Mulish200}) format('truetype')`,
};

const mulish200Italic = {
  fontFamily: 'Mulish',
  fontWeight: '200',
  fontStyle: 'italic',
  src: `url(${Mulish200Italic}) format('truetype')`,
};

const mulish300 = {
  fontFamily: 'Mulish',
  fontWeight: '300',
  fontStyle: 'normal',
  src: `url(${Mulish300}) format('truetype')`,
};

const mulish300Italic = {
  fontFamily: 'Mulish',
  fontWeight: '300',
  fontStyle: 'italic',
  src: `url(${Mulish300Italic}) format('truetype')`,
};

const mulish400 = {
  fontFamily: 'Mulish',
  fontWeight: '400',
  fontStyle: 'normal',
  src: `url(${Mulish400}) format('truetype')`,
};

const mulish400Italic = {
  fontFamily: 'Mulish',
  fontWeight: '400',
  fontStyle: 'italic',
  src: `url(${Mulish400Italic}) format('truetype')`,
};

const mulish500 = {
  fontFamily: 'Mulish',
  fontWeight: '500',
  fontStyle: 'normal',
  src: `url(${Mulish500}) format('truetype')`,
};

const mulish500Italic = {
  fontFamily: 'Mulish',
  fontWeight: '500',
  fontStyle: 'italic',
  src: `url(${Mulish500Italic}) format('truetype')`,
};

const mulish600 = {
  fontFamily: 'Mulish',
  fontWeight: '600',
  fontStyle: 'normal',
  src: `url(${Mulish600}) format('truetype')`,
};

const mulish600Italic = {
  fontFamily: 'Mulish',
  fontWeight: '600',
  fontStyle: 'italic',
  src: `url(${Mulish600Italic}) format('truetype')`,
};

const mulish700 = {
  fontFamily: 'Mulish',
  fontWeight: '700',
  fontStyle: 'normal',
  src: `url(${Mulish700}) format('truetype')`,
};

const mulish700Italic = {
  fontFamily: 'Mulish',
  fontWeight: '700',
  fontStyle: 'italic',
  src: `url(${Mulish700Italic}) format('truetype')`,
};

const mulish800 = {
  fontFamily: 'Mulish',
  fontWeight: '800',
  fontStyle: 'normal',
  src: `url(${Mulish800}) format('truetype')`,
};

const mulish800Italic = {
  fontFamily: 'Mulish',
  fontWeight: '800',
  fontStyle: 'italic',
  src: `url(${Mulish800Italic}) format('truetype')`,
};

const mulish900 = {
  fontFamily: 'Mulish',
  fontWeight: '900',
  fontStyle: 'normal',
  src: `url(${Mulish900}) format('truetype')`,
};

const mulish900Italic = {
  fontFamily: 'Mulish',
  fontWeight: '900',
  fontStyle: 'italic',
  src: `url(${Mulish900Italic}) format('truetype')`,
};

const pridiBold = {
  fontFamily: 'Pridi',
  fontWeight: '700',
  src: `url(${PridiBold}) format('truetype')`,
};

const pridiSemiBold = {
  fontFamily: 'Pridi',
  fontWeight: '600',
  src: `url(${PridiSemiBold}) format('truetype')`,
};

const pridiMedium = {
  fontFamily: 'Pridi',
  fontWeight: '500',
  src: `url(${PridiMedium}) format('truetype')`,
};

const pridiRegular = {
  fontFamily: 'Pridi',
  fontWeight: '400',
  src: `url(${PridiRegular}) format('truetype')`,
};

const pridiLight = {
  fontFamily: 'Pridi',
  fontWeight: '300',
  src: `url(${PridiLight}) format('truetype')`,
};

const pridiExtraLight = {
  fontFamily: 'Pridi',
  fontWeight: '200',
  src: `url(${PridiExtraLight}) format('truetype')`,
};

// All the following keys are optional.
// We try our best to provide a great default value.
const palette = {
  primary: {
    main: '#00A79D',
    main25: '#E7F2F8',
    main25_2: '#DBF3E5',
    hover: '#00C0B5',
    contrastText: common.white,
  },
  secondary: {
    main: '#514E81',
    dark: '#262262',
    dark60: '#7D7AA1',
    dark80: '#514E81',
    light: '#ECEBFF',
    light50: '#F5F5FF',
    light25: '#FAFAFF',
    sat: '#4A458E',
    sat17: '#E0DFEC',
    sat30: '#C9C7DD',
    sat30_45: '#E3E2F0',
    sat50: '#A4A2C6',
    sat50_plus: '#8381b1',
    sat50_placeholder: '#C5C4DE',
    rouge: '#D7225D',
  },
  error: {
    main: '#D7225D',
    dark: '#991842',
    main40: '#EFA7BE',
    main5: '#FDF4F7',
  },
  link: {
    blue: '#00ADF6',
    blue10: 'rgba(0, 173, 246, 0.1)',
  },
  success: {
    main: '#59CD58',
    overlay: '#33CCC0',
    dark: '#004D48',
    light: '#E1F2F6',
    contrastText: common.white,
  },
  tableToolbar: {
    main: '#7D7AA1',
  },
  background: {
    default: '#ffffff',
  },
  genericFont: {
    main: '#45484D',
    light: '#7E838C',
    caption: '#C0C3C8',
  },
  gray: {
    main3: '#828282',
    main5: '#E0E0E0',
    main6: '#F2F2F2',
  },
  warning: {
    main: '#F2AE00',
    main5: '#F5F1F2',
  },
  // Used by `getContrastText()` to maximize the contrast between the background and
  // the text.
  contrastThreshold: 2,
  // Used to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.2,
};

const fontFamily1 = [
  "Pridi",
  "Mulish",
  "Helvetica Nueue",
  "Arial",
];

const fontFamily2 = [
  "Mulish",
  "Pridi",
];

var getFontFamily = function (ff) {
  let ret = '';
  for (let i = 0; i < ff.length; i++) {
    ret += `"${ff[i]}"${i < ff.length - 1 ? ', ' : ''}`;
  }
  return ret;
}

const displayFonts = getFontFamily(fontFamily1);
const readingFonts = getFontFamily(fontFamily2);

const fonts = {
  //styleName: Button Big - Desktop;
  buttonBigDesktop: {
    fontFamily: readingFonts,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '18px',
    letterSpacing: '0.04em',
  },

  //styleName: Verikai - Button Primary - Desktop;
  buttonPrimaryDesktop: {
    fontFamily: readingFonts,
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '17px',
    letterSpacing: '0em',
  },

  //styleName: Button Small - Desktop;
  buttonSmallDesktop: {
    fontFamily: readingFonts,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '16px',
    letterSpacing: '0.04em',
    textAlign: 'left',
  },


  //styleName: Text Big Bold - Mobile;
  textBigMobile: {
    fontFamily: readingFonts,
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '29px',
    letterSpacing: '0em',
  },
  //styleName: Text Small - Desktop;
  textSmallDesktop: {
    fontFamily: readingFonts,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '27px',
    letterSpacing: '0em',
    textAlign: 'left',
  },

  //styleName: Text Small - Mobile;
  textSmallMobile: {
    fontFamily: readingFonts,
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '23px',
    letterSpacing: '0em',
  },

  //styleName: Text Medium - Desktop;
  textMediumDesktop: {
    fontFamily: readingFonts,
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '30px',
    letterSpacing: '0em',
  },

  //styleName: Text Tiny Bold - Desktop;
  textTinyDesktop: {
    fontFamily: readingFonts,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0em',
  },

  //styleName: Text Tiny Bold - Desktop;
  textTinyBoldDesktop: {
    fontFamily: readingFonts,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0em',
  },

  //styleName: Verikai - H3 - Desktop;
  h3Desktop: {
    fontFamily: displayFonts,
    fontSize: '44px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '48px',
    letterSpacing: '0em',
  },

  //styleName: H3 Sans - Mobile;
  h3SansMobile: {
    fontFamily: readingFonts,
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '31px',
    letterSpacing: '0em',
  },

  //styleName: H4 Sans - Desktop;
  h4SansDesktop: {
    fontFamily: readingFonts,
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '37px',
    letterSpacing: '0em',
  },

  //styleName: H2 Serif - Desktop;
  h2SerifDesktop: {
    fontFamily: displayFonts,
    fontSize: '66px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '73px',
    letterSpacing: '0em',
  },

  //styleName: H3 Serif - Desktop;
  h3SerifDesktop: {
    fontFamily: displayFonts,
    fontSize: '44px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '48px',
    letterSpacing: '0em',
    textAlign: 'left',
  },

  //styleName: Verikai - Label - Desktop;
  labelDesktop: {
    fontFamily: readingFonts,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0em',
  },

  //styleName: Label Small Regular - Mobile;
  labelSmallRegularMobile: {
    fontFamily: readingFonts,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0em',
  },

  //styleName: Label Small Bold - Mobile;
  labelSmallBoldMobile: {
    fontFamily: readingFonts,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '16px',
    letterSpacing: '0em',
  },

  //styleName: Label Tiny - Desktop;
  labelTinyDesktop: {
    fontFamily: readingFonts,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0em',
  },

  //styleName: Menu Link - Desktop;
  menuLinkDesktop: {
    fontFamily: readingFonts,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '21px',
    letterSpacing: '0em',
    textAlign: 'left',
  },

  //styleName: Verikai - Subheadline - Desktop;
  sublineDesktop: {
    fontFamily: readingFonts,
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '33px',
    letterSpacing: '0em',
    textAlign: 'left',
  },

  //styleName: Verikai - Table Column Attribute - Desktop;
  tableColumnAttribute: {
    fontFamily: readingFonts,
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '11px',
    letterSpacing: '0em',
  },

  //styleName: Verikai - Table Column Header - Desktop;
  tableColumnHeader: {
    fontFamily: readingFonts,
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '900',
    lineHeight: '11px',
    letterSpacing: '0.02em',
  },

  //styleName: Verikai - Top Nav Item - Desktop;
  topNavItem: {
    fontFamily: readingFonts,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '14px',
    letterSpacing: '0.04em',
    textAlign: 'left',
  },
};

const theme = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          mulish200, mulish200Italic,
          mulish300, mulish300Italic,
          mulish400, mulish400Italic,
          mulish500, mulish500Italic,
          mulish600, mulish600Italic,
          mulish700, mulish700Italic,
          mulish800, mulish800Italic,
          mulish900, mulish900Italic,
          pridiBold, pridiSemiBold,
          pridiMedium, pridiRegular,
          pridiLight, pridiExtraLight,
        ],
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.55)',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 725,
      md: 1056,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: palette,
  typography: {
    fontFamily: fontFamily2,
    h1: {
      fontWeight: 400,
      fontSize: 45,
    },
    h2: {
      fontWeight: 400,
      fontSize: 34,
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 15,
    },
    p: {
      fontSize: 13,
    },
    caption: {
      fontSize: 12,
      color: palette.genericFont.light,
    },
  },
  fonts,
};

export default responsiveFontSizes(createMuiTheme(theme));

const fontsSC = iterateJSStoCSS(fonts);

export {
  palette,
  displayFonts,
  readingFonts,
  fontsSC,
};
