import React from 'react';
import Amplify from 'aws-amplify';
import AppContainer from './components/AppContainer';
import CssBaseline from '@material-ui/core/CssBaseline';
import ProgressPage from './components/verikai/ProgressPage';

import awsconfig from './aws-exports';
import { connect } from 'react-redux';
import { fetchImage } from './lib/fetchImage';
import { initialState } from './redux/reducer';
import getUrlParam from './lib/getUrlParam';
import resetUser from './redux/actions/resetUser';
import setPrepopulate from './redux/actions/setPrepopulate';
import setReferrerLogo from './redux/actions/setReferrerLogo';
import { withRouter } from "react-router-dom";
import Snackbar from './components/Snackbar';
import setIsWhiteLabel from './redux/actions/setIsWhiteLabel';
import "./theme.css";

Amplify.configure(awsconfig);

const mapDispatchToProps = dispatch => ({
  setPrepopulate: v => dispatch(setPrepopulate(v)),
  setReferrerLogo: v => dispatch(setReferrerLogo(v)),
  resetUser: () => dispatch(resetUser()),
  setIsWhiteLabel: v => dispatch(setIsWhiteLabel(v))
});

class App extends React.Component {

  constructor(props)
  {
    super(props);
    this.state = {
      init: false,
    };


  }

  registerGaPageView()
  {
    const path = this.props.location.pathname + this.props.location.search;
    window.gtag('set', 'page_path', path);
    window.gtag('event', 'page_view');
  }

  componentDidUpdate(prevProps)
  {
    if (process.env.NODE_ENV === 'production' && this.props.location !== prevProps.location) {
      this.registerGaPageView();
    }
  }

  // Mount
  async componentDidMount()
  {
    process.env.NODE_ENV === 'production' && this.registerGaPageView();
    this.props.setIsWhiteLabel(process.env.REACT_APP_WHITE_LABEL)
    //  this.props.se tIsWhiteLabel(true)

    // Mount.1. Snag Pre-population Params
    let type = getUrlParam("type", this.props.location);
    let email = getUrlParam("email", this.props.location);
    let name = getUrlParam("name", this.props.location);
    let website = getUrlParam("websiteUrl", this.props.location);
    let firstName = getUrlParam("firstName", this.props.location);
    let lastName = getUrlParam("lastName", this.props.location);
    let phone = getUrlParam("phone", this.props.location);
    let referrer = getUrlParam("referrer", this.props.location);

    // Mount.2. Broker Pre-population
    if(type === 'broker') {

      // Mount.2.1. All Params Required
      if
      (
        email
        && name
        && firstName
        && lastName
      )
      {
        // Mount.2.1.1. Set the Url Params
        this.props.setPrepopulate({
          ...initialState.user,
          email,
          accountType: 'Broker',
          referrer,
          businessInformation: {
            ...initialState.user.businessInformation,
            brokerageName: name,
            website,
          },
          account: {
            ...initialState.user.account,
            firstName,
            lastName,
            phoneNumber: !!phone ? phone : '',
            phoneNumberValid: !!phone,
          },
        });
      }
    }

    // Mount.2. If It Exists, Fetch the Logo
    if(referrer) {
      const fetchedImage = await fetchImage({
        route: `logos/${referrer}`,
      });
      this.props.setReferrerLogo(fetchedImage);
    }

    // Mount.3. Clear the Url Params
    this.setState({
      init: true,
    });
  }

  render()
  {
    return (<>
      <CssBaseline />
      {
        !this.state.init
          ? <ProgressPage />
          : <AppContainer />
      }
      <Snackbar/>
    </>);
  }
}

export default withRouter(connect(null, mapDispatchToProps)(App));

