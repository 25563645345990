const underwriterFormSteps = [
  {
    label: 'Business information',
    route: '/business-information',
  },
  {
    label: 'Person to contact',
    route: '/person-to-contact',
  },
];

export default underwriterFormSteps;
