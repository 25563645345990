import React from 'react';
import 'styled-components/macro';

import HelperText from './HelperText';
import InputBase from '@material-ui/core/InputBase';

import { palette } from '../../theme';

export default class VerikaiTextFieldBase extends React.Component {

  constructor(props)
  {
    super(props);
    this.state = {
      focused: false,
    };
  }

  handleFocus()
  {
    this.setState({
      focused: true,
    });
  }

  handleBlur()
  {
    this.setState({
      focused: false,
    });
  }

  render() {

    const {
      children,
      error,
      helperText,
      helperTextOff,
      label,
      ...other
    } = this.props;

    return <div
      css={`
        width: 100%;
      `}
    >
      {
        this.props.label
          ? <Label>{this.props.label}</Label>
          : null
      }
      <InputBase
        className={'input-field focused-' + this.state.focused}
        css={`
          background-color: #fff;
          height: 50px;
          border-radius: 5px;
          border: ${
            this.state.focused
              ? `2px solid ${palette.secondary.dark}`
              : `1px solid ${palette.secondary.sat30}`
          };
          padding-left: 20px;
          padding-right: 20px;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
          color: ${palette.secondary.dark};
          margin-bottom: 4px;
        `}
        inputProps={{
          onFocus: this.handleFocus.bind(this),
          onBlur: this.handleBlur.bind(this),
        }}
        {...other}
      >
        {this.props.children}
      </InputBase>
      {
        this.props.helperTextOff
          ? null
          : <HelperText
            error={this.props.error}
            open={this.props.helperText}
          >
            {this.props.helperText}
          </HelperText>
      }

    </div>;
  }
}

class Label extends React.Component
{
  render()
  {
    return <div
      css={`
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.04em;
        color: ${palette.secondary.dark};
        margin-bottom: 8px;
      `}
    >
      {this.props.children}
    </div>
  }
}

export {
  Label,
};
