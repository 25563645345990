// Setup.1. Core
import { faArrowRight, faEnvelope, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import 'styled-components/macro';
import { palette } from '../theme';
import HelperText from './verikai/HelperText';
import setReferrerLogo from '../redux/actions/setReferrerLogo';
import setUser from '../redux/actions/setUser';
import emailValidTest from '../lib/emailValidTest';
import API from '../lib/API';
import {links} from "../assets/links";

import slash from "../assets/slash.svg";
import { Button, InputBase } from '@material-ui/core';

interface LoginFormProps {
    mobile?: boolean;
}
// Root
export const LoginForm = (props: LoginFormProps) => {
    const propsEmail = useSelector((state: RootStateOrAny) => state.user.email);
    const prepopulate = useSelector((state: RootStateOrAny) => state.prepopulate);
    const referrerLogo = useSelector((state: RootStateOrAny) => state.referrerLogo);

    const [errorMessage, setErrorMessage] = React.useState('');
    const [email, setEmail] = React.useState(propsEmail || '');
    const [submitting, setSubmittingState] = React.useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const init = async () => {
            await window.pendo.initialize();
        }
        const submit = async () => {
            await handleSubmit();
        }

        init();
        if(!prepopulate && referrerLogo) {
            dispatch(setReferrerLogo(''));
        }
        if(email && prepopulate) {
            submit();
        }
    }, [])

    const handleChangeInput = (e: any) => 
    {
        setEmail(e.target.value);
        setErrorMessage('');
    }
    
    const handleSubmit = async (e ?: any) => 
    {
        if(e) {
          e.preventDefault();
        }

        setSubmittingState(true);
    
        window.pendo.updateOptions({
          visitor: {
            email: email,
          },
        });
    
        // 1. Check Valid Email
        let valid = emailValidTest(email);
        if(!valid)
        {
            setErrorMessage('You must enter a valid email');
        }
        else
        {
          let api = new API();
          let valid = await api.get({
            path: `/check-email?email=${email}${process.env.REACT_APP_WHITE_LABEL == "true" && `&underwriter=${process.env.REACT_APP_WHITE_LABEL_CUSTOMER}`}`,
          });
          if(valid.user_exists)
          {
            if(process.env.REACT_APP_WHITE_LABEL == "true") {
                if(valid.has_relationship) {
                    history.push('/broker-connected');
                } else {
                    history.push('/create-broker-connection');
                }
            } else {
                setErrorMessage('Email is currently in use');
            }
            // setModalStatus(true);
          }
          else
          {
            let sendVerificationCode = await api.get({
              path: `/verify-email?email=${email}&underwriter=${process.env.REACT_APP_WHITE_LABEL_CUSTOMER}`
            });
            dispatch(setUser({
              email: email,
              emailVerified: false
            }));
            if(sendVerificationCode)
            {
              history.push('/verify-email');
            }
          }
        }
        
        setSubmittingState(false);
    }
    
  // Root.999. Render
    return <div
        id='login-form'
        css={`
            width: 333px;
            border-radius: 8px;
            background-color: ${palette.secondary.light25};
            padding: 32px 24px;
            box-shadow: inset 0px -4px 0px ${palette.warning.main};
            @media (max-width: 850px) {
                width: 100%;
                border-radius: 0;
                background-color: ${palette.secondary.dark};
                box-shadow: none;
            }
        `}
    >

        {/* Create Account Title */}
        <div
        className='create-account-title'
        css={`
            font-size: 24px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0px;
            color: ${palette.secondary.dark};
            text-align: left;
            @media (max-width: 850px) {
                line-height: 30px;
                color: ${palette.secondary.light};
                text-align: center;
            }
        `}
        >
            <span
                className='sign-up-mobile'
                css={`
                    font-weight: 900;
                    margin-right: 6px;
                    margin-bottom: 0;
                    @media (max-width: 850px) {
                        margin-bottom: 6px;
                        color: ${palette.warning.main};
                    }
                `}
            >
                {`Sign up${props.mobile ? " here." : '.'}`} 
                {props.mobile ? <br/> : null}
            </span> 
            It's 
            <span
                className='free'
                css={`
                    font-weight: 900;
                    color: ${palette.warning.main};
                    margin: 0 6px;
                    position: relative;
                `}
            >
                free
                <img css={`position: absolute; top: 24px; left: -3px;`} src={slash}/>
            </span> 
            to use!
        </div>

        <summary
            className='signUpSummary'
            css={`
                margin-top: 18px;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: -0.30000001192092896px;
                position: relative;
                color: ${palette.secondary.dark};
                text-align: left;
                @media (max-width: 850px) {
                    color: ${palette.secondary.light};
                    text-align: center;
                }
            `}
        >
            Join the Radion portal
            Create your brokerage’s account to submit
            RFPs to Radion
        </summary>


        {/* Input */}
        <form
        noValidate
        onSubmit={handleSubmit}
        >
        <InputBase
            autoFocus
            className='login-email-input'
            css={`
                margin-top: 32px;
                border: 1px solid ${palette.secondary.sat30};
                background-color: ${palette.secondary.light50};
                border-radius: 8px;
                height: 60px;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 12px;
                padding-bottom: 12px;
                font-family: Mulish;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                color: ${palette.secondary.sat};
                margin-bottom: 7px;
            `}
            fullWidth
            name="email"
            startAdornment={<FontAwesomeIcon css={`margin-right: 8px;`} icon={faEnvelope}/>}
            placeholder='Type your e-mail'
            onChange={handleChangeInput}
            disabled={submitting}
            value={email}
        />

        {/* Helper Text */}
        <HelperText
            error
            open={errorMessage}
            mobile={props.mobile}
        >
            {errorMessage}
        </HelperText>

        {/* Submit Button */}
        <Button
            fullWidth
            className='sign-up-btn'
            css={`
            color: ${palette.warning.main};
            box-shadow: none;
            margin-top: 7px;
            text-transform: none;
            font-family: Mulish;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: 21px;
            letter-spacing: 0em;
            background-color: ${palette.secondary.dark};
            padding-top: 14.5px;
            padding-bottom: 14.5px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                box-shadow: none;
                background-color: ${palette.secondary.dark80};
            }
            margin-bottom: 8px;
            @media (max-width: 850px) {
                color: ${palette.secondary.light25};
                background-color: ${palette.primary.main};
            }
            `}
            variant="contained"
            type="submit"
            disabled={submitting}
        >
            <div className='sign-btn-label'>
            {
                submitting
                ? 'Creating...'
                : 'Get started'
            }
            </div>

            {
            submitting
                ? null
                : <FontAwesomeIcon
                css={`
                    margin-left: 10px;
                    font-size: 12px;
                    font-weight: 900;
                `}
                icon={faArrowRight}
                />
            }
        </Button>
        </form>
        <div css={`margin: auto; padding-top: 12px; display: flex; justify-content: center;`}>
            <a 
                className='website-link' href={process.env.REACT_APP_WHITE_LABEL == "true" ? links.portal.link : "https://portal.verikai.com"}>Already registered? Login here</a>
        </div>
        {/* <div
        css={`
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: center;
        `}
        >
        Already have an account?&nbsp;
        <a
            css={`
            text-decoration: none;
            color: ${palette.primary.main};
            &:visited {
                color: ${palette.primary.main};
            }
            `}
            href="https://portal.verikai.com"
        >
            Sign in.
        </a>
        </div> */}
    </div>
}

LoginForm.defaultProps = {
}