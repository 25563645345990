import {
  SET_SNACK,
} from "../actionTypes";

export default function setSnack(v) {
  return {
    type: SET_SNACK,
    payload: v,
  };
}
