/* eslint-disable import/first */
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppProvider from './AppProvider';
import reportWebVitals from './reportWebVitals';

//window['LOG_LEVEL'] = 'DEBUG';

ReactDOM.render(<React.StrictMode>
  <AppProvider>
    <App/>
  </AppProvider>
</React.StrictMode>,
document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
