// Setup.1. Core
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { faRugbyBall } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { rgbToHex } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import { palette } from '../theme';
import {links} from '../assets/links';

interface LandingPageToolbarProps {
    button ?: any;
    mobile?: boolean;
}

// Root
export const LandingPageToolbar = (props: LandingPageToolbarProps) => {

  // Root.999. Render
    return <div
        className='landing-page-toolbar'
        css={`
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: rgba(0,0,0,0);
            padding: 0px;
            height: 54.5px;
            z-index:10;
            @media (max-width: 850px) {
                background: ${palette.secondary.dark};
                position: fixed; 
                top: 0; 
                left: 0;
                padding: 10px 18px;
            }
        `}
    >
        <a 
            className='toolbar-link'
            css={`
                margin: 0 60px;
                color: ${palette.secondary.dark};
                font-size: 14px;
                font-weight: 400;
                line-height: 26px;
                letter-spacing: -0.30000001192092896px;
                @media (max-width: 850px) {
                    margin: 0px;
                    color: ${palette.secondary.light25};
                    font-size: 12px;
                }
            `}
            target="_blank" href={process.env.REACT_APP_WHITE_LABEL == "true" ? links.website.link : `https://verikai.com/marketplace`}
        >
            <FontAwesomeIcon css={`margin-right: 14px;`} icon={faLongArrowAltLeft}/> 
            <span css={`font-weight: 800; text-decoration: underline;`}>{process.env.REACT_APP_WHITE_LABEL == "true" ? links.website.name : `Visit Verikai.com`}</span>
        </a> 
        {props.button}
    </div>
}

LandingPageToolbar.defaultProps = {
}