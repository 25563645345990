import React from 'react';
import 'styled-components/macro';

import Button from '@material-ui/core/Button';
import { ChooseOne } from './verikai/QuestionCard';
import Display from './Display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { connect } from 'react-redux';
import {
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { palette } from '../theme';
import setUser from '../redux/actions/setUser';
import { withRouter } from "react-router-dom";

const mapDispatchToProps = dispatch => ({
  setUser: v => dispatch(setUser(v)),
});

const mapStateToProps = state => ({
  user: state.user,
  prepopulate: state.prepopulate,
});

class AccountType extends React.Component {

  componentDidMount()
  {
    if(!this.props.user.email || !this.props.user.emailVerified)
    {
      this.props.history.push('/create-account');
    }
    else if(this.props.prepopulate) {
      this.props.user.accountType === 'Broker' ?
        this.props.history.push('/account-setup') 
        : this.props.history.push('/business-information');
    }
  }

  handleChangeInput(name, value)
  {
    this.props.setUser({
      accountType: value === "I'm a broker"
        ? 'Broker'
        : 'Carrier'
    });
  }

  handleNext()
  {
    this.props.user.accountType === 'Broker' ?
      this.props.history.push('/account-setup') 
      : this.props.history.push('/business-information');
  }

  render()
  {
    return <Display
      title="Great, let's get started!"
      outerBottom={<div
        css={`
          display: flex;
          justify-content: flex-end;
          margin-top: 32px;
          @media (max-width: 850px) {
            background-color: ${palette.secondary.light50};
            flex-direction: column-reverse;
            padding:0 30px;
            margin: 0;
          }
        `}
      >
        <Button
          css={`
            background-color: ${palette.primary.main};
            box-shadow: none;
            margin-top: 7px;
            text-transform: none;
            font-family: Mulish;
            font-size: 15px;
            font-style: normal;
            font-weight: 800;
            line-height: 17px;
            letter-spacing: 0em;
            color: #fff;
            padding-top: 14.5px;
            padding-bottom: 14.5px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              box-shadow: none;
              background-color: ${palette.primary.main};
            }
            margin-bottom: 17px;
          `}
          variant="contained"
          type="submit"
          disabled={!this.props.user.emailVerified || !this.props.user.accountType}
          onClick={this.handleNext.bind(this)}
        >
          <div>
            Next
          </div>
          <FontAwesomeIcon
            css={`
              margin-left: 10px;
              font-size: 12px;
              font-weight: 900;
            `}
            icon={faArrowRight}
          />
        </Button>
      </div>}
    >

      {/* Subtitle */}
      <div
        css={`
          font-family: Mulish;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: left;
          color: ${palette.secondary.dark};
          display: flex;
          margin-bottom: 32px;
          @media (max-width: 850px) {
            display: inline;
          }
        `}
      >
        Are you a&nbsp;<span css={`font-weight: 700`}>Broker</span>&nbsp;or an&nbsp;<span css={`font-weight: 700`}>Underwriter</span>&nbsp;?
      </div>

      <ChooseOne
        choices={[
          {
            name: "I'm a broker",
          },
          {
            name: "I'm an underwriter"
          }
        ]}
        name="accountType"
        onChange={this.handleChangeInput.bind(this)}
        value={
          this.props.user.accountType === 'Broker'
            ? "I'm a broker"
          : this.props.user.accountType === 'Carrier'
            ? "I'm an underwriter"
            : ''
        }
      />
    </Display>;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountType));
