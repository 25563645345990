// Setup.1. Core
import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import { palette } from '../theme';

interface LandingPageFeatureBoxProps {
    mobile?: boolean;
}
// Root
export const LandingPageFeatureBox = (props: LandingPageFeatureBoxProps) => {

    
  // Root.999. Render
    return <div
        css={`
            margin: 0 60px;
            background-color: ${palette.secondary.sat};
            padding: 60px 40px 40px 40px;
            width: calc(100% - 120px);
            @media (max-width: 850px) {
                margin: 0 20px;
                padding: 24px;
                width: calc(100% - 40px);
            }
        `}
    >
        <header
            css={`
                font-size: 2.5vw;
                font-weight: 700;
                line-height: 3vw;
                letter-spacing: -1.85px;
                color: ${palette.secondary.light25};
                @media (max-width: 850px) {
                    font-size: 22px;
                    line-height: 26px;
                    letter-spacing: -0.94px;
                }
                @media (min-width: 1400px) {
                    font-size: 42px;
                    line-height: 48px;
                }
            `}
        >
            No IHQ's. <br/>No need for past claims. <br/>Free to use. 
        </header>
        <a 
            css={`
                font-size: 22px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: -0.23105771839618683px;
                text-align: right;
                color: ${palette.secondary.light25};
                margin-top: 14px;
                display: block;
                width: 100%;
                @media (max-width: 850px) {
                    font-size: 14px;
                    line-height: 12.5px;
                }
            `}
            target="_blank" 
            href='https://www.verikai.com/marketplace/'
        >Learn more</a>
    </div>
}

LandingPageFeatureBox.defaultProps = {
}