export default function passwordValidTest(value)
{
  let rules = {
    lowercase: /[a-z]/g.test(value),
    uppercase: /[A-Z]/g.test(value),
    digit: /[0-9]/g.test(value),
    specialChar: /[^A-Za-z0-9]/g.test(value),
    charLength: value.length >= 8,
  };

  let messages = [];

  if(!rules.lowercase) messages.push(`At least one lowercase character required.`);
  if(!rules.uppercase) messages.push(`At least one uppercase character required.`);
  if(!rules.digit) messages.push(`At least one numerical character required.`);
  if(!rules.specialChar) messages.push(`At least one special character required.`);
  if(!rules.charLength) messages.push(`At least 8 characters required.`);

  return {
    rules,
    messages,
  };
}
