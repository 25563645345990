export default function prettyInt(value, allowBlank=false, initialValue)
{
  let originalValue = value.toString().trim();
  if(value === '' && !allowBlank)
  {
    value = '0';
  }
  else if(value === '')
  {
    return value;
  }
  value = parseInt(value.replace(/,|\D/g, ''));
  if(isNaN(value))
  {
    value = initialValue !== undefined
      ? initialValue
      : originalValue;
  }
  else
  {
    value = value.toLocaleString('en-US');
  }

  return value;
}
