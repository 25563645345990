import {
  SHIFT_SNACKS,
} from "../actionTypes";

export default function shiftSnacks(v)
{
  return {
    type: SHIFT_SNACKS,
    payload: v,
  };
}
