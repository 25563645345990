import {
  SET_REFERRER_LOGO,
} from "../actionTypes";

export default function setReferrerLogo(v) {
  return {
    type: SET_REFERRER_LOGO,
    payload: v,
  };
}
