// Setup.1. Core
import 'styled-components/macro';
import { palette } from '../theme';
import { useSelector, RootStateOrAny } from 'react-redux';
import { Button } from '@material-ui/core';
import Checkbox from './verikai/Checkbox';
import React, { useEffect, useState } from 'react';
import Display from './Display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faClose, faPaperPlane, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
// import brokerFormSteps from '../lib/brokerFormSteps';
import Steps from './Steps';
import API from '../lib/API';
import { BusinessCard } from './BuisnessCard';
import { Table } from './Table';
import TextField from './verikai/TextFieldBase';
import emailValidTest from '../lib/emailValidTest';
import { faSend } from '@fortawesome/pro-regular-svg-icons';

interface Invitee {
  firstName: string, 
  lastName: string, 
  email: string
}
// Root
export const TeamInvite = () => {

    const { brokerFormSteps, isWhitelabel} = useSelector((state: RootStateOrAny) => state)
    const history = useHistory();
    const [inviteeList, setList] = useState<Array<Invitee>>([]);
    const [showModal, toggleModal] = useState(false);

    const handleBack = () => {

      const route = brokerFormSteps[isWhitelabel ? 1 : 2].route;
      history.push(route);
      // history.push("/communication");
    }

    const handleNext = () => {
        history.push("/create-broker-account");
    }

    // Root.999. Render
    return <Display
      title="Invite your team to join your account"
      outerTop={<Steps
        currentStepNumber={isWhitelabel ? 2 : 3}
        steps={brokerFormSteps}
      />}
      outerBottom={<div
        css={`
          display: flex;
          justify-content: space-between;
          margin-top: 32px;
          @media (max-width: 850px) {
            flex-direction: column-reverse;
            padding: 0 30px;
            margin-top: 0px;
          }
        `}
      >

        <Button
          className='website-link'
          css={`
            color: ${palette.link.blue};
            text-transform: none;
            font-family: Mulish;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 0em;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 5px;
          `}
          onClick={() => handleBack()}
        >
          Back
        </Button>

        <Button
          className='next-button'
          css={`
            background-color: ${palette.primary.main};
            box-shadow: none;
            margin-top: 7px;
            text-transform: none;
            font-family: Mulish;
            font-size: 15px;
            font-style: normal;
            font-weight: 800;
            line-height: 17px;
            letter-spacing: 0em;
            color: #fff;
            padding-top: 14.5px;
            padding-bottom: 14.5px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              box-shadow: none;
              background-color: ${palette.primary.main};
            }
            margin-bottom: 17px;
          `}
          variant="contained"
          type="submit"
        //   disabled={!this.props.user.emailVerified || !this.props.user.accountType}
          onClick={() => handleNext()}
        >
          <div>
            Next
          </div>
          <FontAwesomeIcon
            css={`
              margin-left: 10px;
              font-size: 12px;
              font-weight: 900;
            `}
            icon={faArrowRight}
          />
        </Button>
      </div>}
    >

        {/* Subtitle */}
        <div
            css={`
            font-family: Mulish;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: left;
            color: ${palette.secondary.sat};
            display: flex;
            margin-bottom: 8px;
            `}
        >
            Add team members below.
        </div>


        <div
            css={`
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            text-align: left;
            color: ${palette.secondary.sat};
            margin-bottom: 32px;
            `}
        >
            These features are always available for you to updated. Access them by navigating to 
            <span css={`font-weight: 800; margin: 0 5px;`}>SETTINGS</span> 
            (located in the upper right corner of the interface). You can update/change your communication settings when logged into Marketplace. 
        </div>

        <Button
          className="team-invite-btn"
          css={`
            background-color: #F2FCFB;
            border: 1px solid ${palette.primary.main};
            width: 100%;
            box-shadow: none;
            margin-top: 7px;
            text-transform: none;
            font-family: Mulish;
            font-size: 15px;
            font-style: normal;
            font-weight: 800;
            line-height: 17px;
            letter-spacing: 0em;
            color: ${palette.primary.main};
            padding-top: 14.5px;
            padding-bottom: 14.5px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              box-shadow: none;
              background-color: ${palette.primary.main};
              color: #ffffff;
            }
            margin-bottom: 17px;
          `}
          variant="contained"
          type="submit"
        //   disabled={!this.props.user.emailVerified || !this.props.user.accountType}
          onClick={() => toggleModal(true)}
        >
            Invite a team member
          <FontAwesomeIcon
            css={`
              margin-left: 10px;
              font-size: 12px;
              font-weight: 900;
            `}
            icon={faPlusCircle}
          />
        </Button>

        {
            showModal && <InviteeModal 
            close={() => toggleModal(false)} 
            addTeamMember={(firstName: string, lastName:string, email: string) => setList([...inviteeList, {firstName, lastName, email}])}
            />
        }

        {   inviteeList.length > 0 &&
            <div
                css={`
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 14px;
                    text-align: left;
                    color: ${palette.secondary.dark};
                    margin-top: 32px;
                    margin-bottom: 5px;
                `}
            >
                Invited team member
            </div> 
        }

        {/* Table */}
        {   inviteeList.length > 0 &&
            <Table 
                headerItems={[
                    "Name",
                    "Email",
                    ""
                ]}
                headerKeys={["Name", "Email", "Invite"]}
                columnProps={
                    [
                        {
                            "width":"400px", 
                            "text-align":"left",
                            "weight": 700
                        }, 
                        {
                            "width":"600px", 
                            "text-align":"left",
                            "weight": 400
                        }, 
                        {
                            "width":"150px", 
                            "text-align":"left",
                            "weight": 400,
                            "color": `${palette.secondary.sat50}`,
                            "font-size": "10px"
                        }
                ]
                }
                rows={inviteeList.map((item, i) => {
                    return { 
                        "Name": item.firstName + " " + item.lastName,
                        "Email": item.email,
                        "Invite": "INVITE SENT"
                    }
                })}
            />
        }

    </Display>;
}

interface InviteeModalProps {
    close: () => void;
    addTeamMember: (firstName: string, lastName:string, email: string) => void;
  }
  
  const InviteeModal = (props: InviteeModalProps) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [saving, setStatus] = useState(false);

    const handleSubmitNewMember = async () => {
        setStatus(true);

        let api = new API();
        let result;
        try {
            result = await api.post({
                path: `/producer-admin-create-user`,
                body: {
                    first_name: firstName,
                    last_name: lastName,
                    email: email.toLowerCase(),
                    is_white_label: process.env.REACT_APP_IS_WHITE_LABEL,
                    underwriter: process.env.REACT_APP_WHITE_LABEL_CUSTOMER
                },
                errorMessage: `Couldn't invite ${firstName} at this time. Try again later.`,
            })
        } catch(err) {
            console.log(err)
        }
        setStatus(false);
        if(result) {
            props.addTeamMember(firstName, lastName, email);
            props.close();
        } 
    }

      return <div
        className='inviteeModalHolder'
        css={`
            background: rgba(0,0,0,0.8);
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100vw;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 100;
            @media (max-width: 850px) {
              top: 54px;
              justify-content: center;
              align-items: start;
              background-color: ${palette.secondary.light50};
            }
        `}
      >
        <div
        className='invitee-modal'
          css={`
              padding-top: 15px;
              width: 50%;
              min-width: 450px;
              max-width: 700px;
              background-color: ${palette.secondary.light50};
              border-radius: 4px;
              color: ${palette.secondary.dark80};
              @media (max-width: 850px) {
                padding: 24px;
              }
          `}
        >
          <div 
            css={`
              width: 100%; 
              padding: 0 30px;
              cursor: pointer; 
              text-align: right;
              display: flex;
              align-items: center;
              justify-content: space-between;
            `}
            onClick={props.close}
          > 
            <div
            css={`
              font-size: 10px;
              font-style: normal;
              font-weight: 800;
              line-height: 11px;
              letter-spacing: 0.16em;
            `}
            >
              INVITE A TEAM MEMBER
            </div>
            
            <div
                css={`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `}
            >
              <FontAwesomeIcon css={`margin-right: 5px;`} icon={faClose}/> 
              close
            </div>
          </div>

          <div
            css={`
                padding: 30px;
                color: ${palette.secondary.dark80};
            `}
          >
              <header
                css={`
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 31px;
                    margin-bottom: 12px;
                    @media (max-width: 850px) {
                      text-align: center;
                    }
                `}
              >
                Invite a team member to join your account
              </header>
              <summary
                css={`
                    font-size: 18px;
                    line-height: 23.4px;
                    margin-bottom: 31px;
                `}
              >
                Enter a team members information below. Then select “invite” to have them join your team.
              </summary>
                <div
                css={`
                    display flex;

                    @media (max-width: 850px) {
                      flex-direction: column;
                    }
                `}
                >
                    <TextField
                        label={<div css={`font-weight: 400;`}><span css={`font-weight: 700;`}>First name</span> of team member</div>}
                        fullWidth
                        onChange={(e: any) => setFirstName(e.target.value)}
                        name="firstName"
                        value={firstName}
                        type="text"
                    />
                    <div css={`width: 20px;`}/>
                    <TextField
                        label={<div css={`font-weight: 400;`}><span css={`font-weight: 700;`}>Last name</span> of team member</div>}
                        fullWidth
                        onChange={(e: any) => setLastName(e.target.value)}
                        name="lastName"
                        value={lastName}
                        type="text"
                    />
                </div>

                <TextField
                    label={<div css={`font-weight: 400;`}><span css={`font-weight: 700;`}>Email address</span> of team member</div>}
                    fullWidth
                    onChange={(e: any) => setEmail(e.target.value)}
                    name="email"
                    value={email}
                    type="email"
                    error={!emailValidTest(email) && email !== ""}
                    helperText={
                        !emailValidTest(email) && email !== "" && "Please enter a valid email"
                    }
                />

                <Button
                  className='next-button'
                    css={`
                        background-color: ${palette.primary.main};
                        margin-left: auto; 
                        margin-right: 0;
                        box-shadow: none;
                        margin-top: 7px;
                        text-transform: none;
                        font-family: Mulish;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: 17px;
                        letter-spacing: 0em;
                        color: #fff;
                        padding-top: 14.5px;
                        padding-bottom: 14.5px;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover {
                            box-shadow: none;
                            background-color: ${palette.primary.main};
                        }
                        margin-bottom: 17px;
                        @media (max-width: 850px) {
                          width: 100%;
                        }
                    `}
                    variant="contained"
                    type="submit"
                    disabled={!emailValidTest(email) || firstName == "" || lastName == "" || saving}
                    onClick={() => handleSubmitNewMember()}
                >
                <div>
                    { saving ? "Sending..." : "Send an invite"}
                </div>
                <FontAwesomeIcon
                    css={`
                    margin-left: 10px;
                    font-size: 12px;
                    font-weight: 900;
                    `}
                    icon={faPaperPlane}
                />
                </Button>
          </div>
        </div>
    </div>
  }

