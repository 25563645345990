import React, {useEffect, useState} from 'react';
import "styled-components/macro";
import {CSSTransition} from 'react-transition-group';

import Button from '@material-ui/core/Button';
import { SnackbarContent } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./css/Snackbar.css"
import { connect } from 'react-redux';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { palette } from "../theme";
import shiftSnacks from '../redux/actions/shiftSnacks';
import { useHistory } from 'react-router-dom';

const mapDispatchToProps = dispatch => {
  return {
    shiftSnacks: (i) => dispatch(shiftSnacks(i)),
  };
};

const mapStateToProps = state => {
  return {
    snacks: state.snacks,
   };
}

const background = {
  default: "#FAFAFF",
  success: "#F2FCFB",
  info: "#F0FAFF",
  warning: "#FFFAEE",
  error: "#FEF8FA"
}

const border = {
  default: "#262262",
  success: "#00A79D",
  info: "#00ADF6",
  warning: "#F2AE00",
  error: "#D7225D"
}

function MySnackbarContentWrapper(props) 
{
  const [inProp, setInProp] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setInProp(true);
    setTimeout(() => {
      setInProp(false);
    }, props.snack.timeout ? props.snack.timeout : 10000 + props.index*1000)
  }, []);

  return <CSSTransition
    in={inProp}
    timeout={300}
    classNames="alert"
    unmountOnExit
    onExited={() => props.onClose(props.snack.message)}
  >
    <div
      className="alert"
      onClick={props.snack.link ? () => {
        history.push(props.snack.link)
        setInProp(false);
      }: null}
      css={`
        ${props.snack.link && 'cursor: pointer;'}
        position: relative;
        transition: all ease 0.5s;
        &.MuiSnackbarContent-message {
          width: 100%;
        }
      `}
    >
      <SnackbarContent
        css={`
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            color: ${palette.secondary.dark};
            border-radius: 5px;
            border: 2px solid ${border[props.snack.variant]};
            background-color: ${background[props.snack.variant]};
            width: 330px;
            transition: all 0.5s ease;
            & .MuiSnackbarContent-message {
              width: 100%;
            }
        `}
        message={<div
          css={`
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
          `}
        >
          <div
            css={`
              flex-grow: 1;
            `}
          >
            <div
              css={`
                font-weight: 600;
              `}
            >
              {props.snack.message}
            </div>
            <div
              css={`
                font-weight: 300;
              `}
            >
              {props.snack.body}
            </div>
          </div>
          <div>
            {
              (props.snack.dismissable === undefined || props.snack.dismissable) &&
                <Button
                  css={`
                    min-width: 0px;
                    border-radius: 50%;
                    &:hover {
                      color: #fff;
                      background-color: ${background[props.snack.variant]};
                    }
                  `}
                  onClick={(e) => {
                    e.stopPropagation();
                    setInProp(false)
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    css={`
                      color: ${palette.secondary.dark};
                    `}
                  />
                </Button>
            }
          </div>
        </div>}
      />
    </div>
  </CSSTransition>
}

class ConsecutiveSnackbars extends React.Component {

  constructor(props)
  {
    super(props);
    this.state = {
      snacks: [{
        message: '',
        key: 'nullNotification',
        variant: ''
      }],
      open: false,
    };
  }

  componentDidMount()
  {
    if(this.props.snacks.length > 0 & !this.state.open)
    {
      this.setState({
        open: true,
        snacks: this.props.snacks,
      });
    }
  }

  componentDidUpdate(prevProps, prevState)
  {
    // Incoming Message
    if(this.props.snacks.length > 0 && !prevState.open)
    {
      this.setState({
        open: true,
        snacks: this.props.snacks,
      });
    }

    // Queue is empty
    else if(this.props.snacks.length === 0 && prevState.open)
    {
      this.setState({
        open: false,
      });
    }

    // Queue has Changed
    else if
    (
      this.props.snacks.length > 0 && prevProps.snacks.length > 0
      && prevState.open
      && (prevProps.snacks[0].key !== this.props.snacks[0].key || this.props.snacks.length !== this.props.snacks.length)
    )
    {
      this.setState({
        open: false
      });
    }
  }

  handleClose(message)
  {
    this.props.shiftSnacks(message);
  };

  render()
  {
    return <div
      css={`
        position: fixed;
        top: 80px;
        right: 50px;
        display: flex;
        flex-direction: column;
        z-index: 1400;
      `}
    >
      {
        this.props.snacks.map((snack, i) => {
          return <MySnackbarContentWrapper
              id={snack.message}
              index={i}
              key={snack.key}
              snack={snack}
              open={this.state.open}
              onClose={this.handleClose.bind(this)}
            />
        })
      }
    </div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsecutiveSnackbars);