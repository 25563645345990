// Setup.1. Core
import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import { palette } from '../theme';
import macBook from "../assets/Macbook.svg";
import macBookMobile from "../assets/macbookMoblie.svg";

interface LandingPageMarketplaceImgProps {
    mobile?: boolean;
}

// Root
export const LandingPageMarketplaceImg = (props: LandingPageMarketplaceImgProps) => {

    
  // Root.999. Render
    return <>
        <header
            css={`
                margin: 0 60px;
                font-size: 4vw;
                font-weight: 600;
                line-height: 5vw;
                letter-spacing: -2.4000000953674316px;
                color: ${palette.secondary.dark};
                text-align: left;
                @media (max-width: 850px) {
                    margin: 0 25px 25px 25px;
                    font-size: 24px;
                    line-height: 32px;
                    letter-spacing: -0.3px;
                    text-align: center;
                }
                @media (min-width: 1400px) {
                    font-size: 56px;
                    line-height: 68px;
                }
            `}
        >
            Great work starts in the Marketplace
        </header>      

        <img 
            css={`
                margin-top: 35px;
                width: 100%;
                @media (max-width: 850px) {
                    margin-top: 0px;
                }
            `}
            src={props.mobile ? macBookMobile : macBook}
        />      

        <summary
            css={`
                margin: 0 60px;
                text-align: left;
                margin-top: 40px;
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: -0.3px;
                color: ${palette.secondary.dark};

                @media (max-width: 850px) {
                    margin: 25px;
                    text-align: center;
                    margin-top: 20px;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.11px;
                }
            `}
        >
            Join our network with over a dozen carriers and MGU's using Verikai to bind groups today.
        </summary>
    </>
}

LandingPageMarketplaceImg.defaultProps = {
}