// Setup.1. Core
import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import { palette } from '../theme';
import videoStart from "../assets/videoStart.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPlay } from '@fortawesome/free-solid-svg-icons';


interface LandingPageVideoProps {
    mobile?: boolean;
}
// Root
export const LandingPageVideo = (props: LandingPageVideoProps) => {
    const [playVideo, setVideoState] = React.useState(false);
    const [showVideoModal, setVideoModalState] = React.useState(false);


    if(showVideoModal) {
        return <div
            css={`
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #262262f2;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1000;
            `}
        >
            <div 
                className="video-container"
                css={`
                    margin: ${props.mobile ? "20px" : "0 60px"};
                    position: relative;
                    height: 50%;
                    width: 40%;
                    padding: 48.25% 0 0 0;
                    background-color: rgba(0,0,0,0);
                `}
            >
                <div
                    css={`
                        position: fixed;
                        font-size: 20px;
                        color: white;
                        top: 20px;
                        right: 20px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        z-index: 10001;
                        padding: 5px 10px;
                        border-radius: 4px;
                        transition: all ease 0.25s;
                        &:hover {
                            background-color: rgba(255,255,255,0.5);
                        }
                    `}
                    onClick={() => { setVideoModalState(false); setVideoState(false)}}
                >
                    <FontAwesomeIcon css={`margin: 4px 6px 0px 0px`} icon={faClose}/> close
                </div>
                <iframe 
                    src="https://player.vimeo.com/video/545750813?h=eb02b0fc1a&autoplay=1&title=0&byline=0&portrait=0" 
                    allow="autoplay; fullscreen;" 
                    css={`
                        border: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                    `}
                />
            </div>
        </div>
    }
    
  // Root.999. Render
    return <div
        css={`
            padding-top: ${props.mobile ? "45px" : "0"};
            ${props.mobile ? `background-color: ${palette.secondary.light50};` : ""}
        `}
    >
        <div 
            className="video-container"
            css={`
                margin: ${props.mobile ? "20px" : "0 60px"};
                position: relative;
                height: 0;
                width: cacl(100% - 120px);
                padding: 48.25% 0 0 0;
                background-color: white;
                ${props.mobile ? `background-color: ${palette.secondary.light50};` : ""}
            `}
        >
            { !playVideo ? 
                <div
                    css={`
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        border-radius: 8px;
                        box-shadow: 0px 0px 10px rgba(125, 122, 161, 0.4);
                        background: url(${videoStart});
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    `}
                    onClick={() => {
                        setVideoState(true);
                        if(!props.mobile) {
                            setVideoModalState(true);
                        }
                    }}
                >
                    <div
                        className='playBtn'
                        css={`
                            width: 68px;
                            height: 68px;
                            border-radius: 50%;
                            background-color: ${palette.secondary.dark};
                            color: white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 20px;
                            transition: all ease 0.25s;
                            & :hover {
                                background-color: ${palette.secondary.dark60};
                            }
                        `}
                    >
                        <FontAwesomeIcon icon={faPlay}/>
                    </div>
                </div>
                : 
                <iframe 
                    src="https://player.vimeo.com/video/545750813?h=eb02b0fc1a&autoplay=1&title=0&byline=0&portrait=0" 
                    allow="autoplay; fullscreen; picture-in-picture" 
                    css={`
                        border: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        box-shadow: 0px 0px 10px rgba(125, 122, 161, 0.4);
                    `}
                />
            }
        </div>
    </div>
    
}

LandingPageVideo.defaultProps = {
}