import React from 'react';
import 'styled-components/macro';

import Autocomplete from '@material-ui/lab/Autocomplete';
import AutocompleteTextField from './AutocompleteTextField';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { palette } from '../../theme';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default class VerikaiAutocomplete extends React.Component
{
  render()
  {
    return <Autocomplete
      autoComplete
      autoHighlight
      disableCloseOnSelect
      getOptionLabel={option => option}
      multiple
      onChange={this.props.onChange}
      options={this.props.options}
      renderInput={(params) => <AutocompleteTextField {...params} />}
      renderOption={(option, { selected }) => {
        return <div
          css={`
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0em;
            color: ${palette.secondary.dark};
          `}
        >
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </div>
      }}
      limitTags={2}
      value={this.props.value}
    />;
  }
}
