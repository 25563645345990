import {
  SET_USER,
} from "../actionTypes";

export default function setUser(v) {
  return {
    type: SET_USER,
    payload: v,
  };
}
