import React from 'react';
import 'styled-components/macro';

import { AnimatePresence, motion } from "framer-motion";
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Checkbox from './verikai/Checkbox';
import Display from './Display';
import Steps from './Steps';
import TextField from './verikai/TextFieldBase';

import { connect } from 'react-redux';
// import brokerFormSteps from '../lib/brokerFormSteps';
import formatPhoneNumber from '../lib/formatPhoneNumber';
import { palette } from '../theme';
import passwordValidTest from '../lib/passwordValidTest';
import resetUser from '../redux/actions/resetUser';
import setPassword from '../redux/actions/setPassword';
import setUser from '../redux/actions/setUser';
import toggleTermsAndConditionsAgreed from '../redux/actions/toggleTermsAndConditionsAgreed';
import { withRouter } from "react-router-dom";
import { fetchTermsAndConditions } from '../lib/fetchTermsAndConditions';
import API from '../lib/API';


const mapDispatchToProps = dispatch => ({
  resetUser: () => dispatch(resetUser()),
  setUser: v => dispatch(setUser(v)),
  setPassword: v => dispatch(setPassword(v)),
  toggleTermsAndConditionsAgreed: () => dispatch(toggleTermsAndConditionsAgreed()),
});

const mapStateToProps = state => ({
  user: state.user,
  password: state.password,
  termsAndConditionsAgreed: state.termsAndConditionsAgreed,
  isWhiteLabel: state.isWhitelabel,
  brokerFormSteps: state.brokerFormSteps
});

class AccountSetup extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      saving: false,
      error: '',
    };
  }

  componentDidMount()
  {
    const b = this.props.user.businessInformation;
    if(!this.props.user.email || !this.props.user.emailVerified)
    {
      this.props.history.push('/create-account');
    }
    else if(!this.props.user.accountType)
    {
      this.props.history.push('/account-type');
    }
    else
    {
      this.props.setPassword({
        termsAndConditionsAgreed: false,
        password: '',
        confirmPassword: '',
        passwordValid: false,
        passwordValidMessage: '',
      });
    }
  }

  handleChangeInputBusiness(e)
  {
    this.props.setUser({
      businessInformation: {
        ...this.props.user.businessInformation,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleChangeInput(e)
  {
    let value = e.target.value;
    let valueValid = undefined;
    let valueValidName = undefined;
    let name = e.target.name;
    if(name === 'phoneNumber')
    {
      valueValid = false;
      valueValidName = 'phoneNumberValid';
      value = formatPhoneNumber(value);
      if(value.length === 12)
      {
        valueValid = true;
      }
    }

    this.setState({
      error: '',
    });
    this.props.setUser({
      account: {
        ...this.props.user.account,
        [e.target.name]: value,
        ...(valueValidName !== undefined && {
          [valueValidName]: valueValid,
        }),
      },
    });
  }

  handleBack()
  {
    this.props.history.push('/account-type');
  }


  handleNext()
  {
    const route = this.props.brokerFormSteps[1].route;
    this.props.history.push(route);
  }

  handleChangePassword(e)
  {
    this.setState({
      error: '',
    });
    let value = e.target.value;
    let name = e.target.name;
    if(name === 'password')
    {
      let passwordValidTestResults = passwordValidTest(value);
      this.props.setPassword({
        password: value,
        passwordValid: passwordValidTestResults.messages.length === 0 || value === '',
        passwordValidMessage: value === '' || passwordValidTestResults.messages.length === 0
          ? ''
          : passwordValidTestResults.messages[0],
        confirmPassword: '',
      });
    }
    else
    {
      this.props.setPassword({
        [name]: value,
      });
    }

  }

  handleChangeTermsAndConditions()
  {
    this.props.toggleTermsAndConditionsAgreed();
  }

  async updateWhiteLabelRelationship() {
    let api = new API();
    try {
      await api.post({
          path: `/update-relationship`,
          body: {
            underwriters: process.env.REACT_APP_WHITE_LABEL_CUSTOMER,
            isWhiteLabel: 1
          },
      });
    } catch(err) {
      console.log(err);
    }
  }


  async signInCreatedUser()
  {
    try {
      const user = await Auth.signIn(
        this.props.user.email.toLowerCase().trim(),
        this.props.password.password
      );
      this.setState({
        saving: false,
      });
      this.props.setPassword({
        termsAndConditionsAgreed: false,
        password: '',
        confirmPassword: '',
        passwordValid: false,
        passwordValidMessage: '',
      });
      process.env.REACT_APP_WHITE_LABEL == "true" && await this.updateWhiteLabelRelationship();
      const route = this.props.brokerFormSteps[1].route;
      this.props.history.push(route);
    } catch (err) {
      console.log(err)
    }
  }


  async createAccount()
  {
    this.setState({
      saving: true,
    });

    try {
      // Check if the brokerage already exists...
      let api = new API();
      const response = await api.get({
          path: `/check-if-broker-already-exists?brokerage_name=${this.props.user.businessInformation.brokerageName}`,
      });

      if (Object.entries(response).length) {
        throw new Error(`This brokerage name is already active in the Marketplace. Please enter a unique name or request an account from ${response.user_email}.`)
      }

      // 1. Create the User
      await Auth.signUp({
        username: this.props.user.email.toLowerCase().trim(),
        password: this.props.password.password,
        attributes: {
          email: this.props.user.email.toLowerCase().trim(),
          ...(this.props.user.account.phoneNumber && {
            phone_number: `+1${this.props.user.account.phoneNumber.replace(/\D/g, '')}`,
          }),
          given_name: this.props.user.account.firstName,
          family_name: this.props.user.account.lastName,
          'custom:account_type': 'B',
        },
        validationData: {
          brokerageName: this.props.user.businessInformation.brokerageName,
          headquartersLocation: this.props.user.businessInformation.headquartersLocation,
          website: this.props.user.businessInformation.website,
          stoplossGroupsPerYear: this.props.user.businessInformation.stoplossGroupsPerYear,
          tierPlan: this.props.user.tierPlan,
          workEmail: this.props.user.account.workEmail,
          states: this.props.user.businessInformation.states.join(','),
          customerName: process.env.REACT_APP_WHITE_LABEL_CUSTOMER
        }
      });
      this.setState({
        saving: true,
        error: '',
      });
      this.signInCreatedUser();
    } catch (err) {
      let wait = function(ms){
        return new Promise(resolve => setTimeout(resolve, ms));
      };
      if (err.hasOwnProperty('message')) {
        if (err.message.includes('Email not verified.') || err.message.includes('An account with the given email already exists.') || err.message.includes('This brokerage name')) {
          this.props.resetUser();
          // window.location.reload();
        }
      }
      await wait(1000);
      this.props.setPassword({
        termsAndConditionsAgreed: false,
        password: '',
        confirmPassword: '',
        passwordValid: false,
        passwordValidMessage: '',
      });
      
      this.setState({
        saving: false,
        error: err.message ? err.message : 'We were unable to save your information. Please try again.',
      });
    }
  }

  render()
  {
    const a = this.props.user.account;
    const finishDisabled = (
      !this.props.user.businessInformation.brokerageName
      || !a.firstName
      || !a.lastName
      || (
        a.phoneNumber
        && !a.phoneNumberValid
      )
      || !this.props.password.password
      || !this.props.password.confirmPassword
      || this.props.password.password !== this.props.password.confirmPassword
      || !this.props.password.passwordValid
      || !this.props.termsAndConditionsAgreed
    );

    const fetchTandC = async (e) => {         
      //we set the href value on the first pass to the proper string and execute the control normally. 
      //All subsequent click will operate normally.
      if(!e.target.href) {
          //set target of [a] element to facilitate clean download process
          e.target.href = await fetchTermsAndConditions({
              route: `join_app/current/VerikaiTermsAndConditions.pdf`,              
          });
          //trigger [a] element to start download with proper file name
          e.target.click();
      }    
    };

    return <Display
      noPaddingBottom
      title="Now, let's setup your account"
      outerTop={<Steps
        currentStepNumber={1}
        steps={this.props.brokerFormSteps}
      />}
      outerBottom={<div
        css={`
          display: flex;
          justify-content: space-between;
          margin-top: 32px;
          @media (max-width: 850px) {
            flex-direction: column-reverse;
            padding: 0 30px;
            margin-top: 0px;
            margin-bottom: 16px;
          }
        `}
      >
        {process.env.REACT_APP_WHITE_LABEL !== "true" ? <Button
          css={`
            color: ${palette.link.blue};
            text-transform: none;
            font-family: Mulish;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 0em;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 5px;
          `}
          onClick={this.handleBack.bind(this)}
        >
          Back
        </Button> : <div/>}

        <Button
          className='next-button'
          css={`
            background-color: ${palette.primary.main};
            box-shadow: none;
            margin-top: 7px;
            text-transform: none;
            font-family: Mulish;
            font-size: 15px;
            font-style: normal;
            font-weight: 800;
            line-height: 17px;
            letter-spacing: 0em;
            color: #fff;
            padding-top: 14.5px;
            padding-bottom: 14.5px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              box-shadow: none;
              background-color: ${palette.primary.main};
            }
            margin-bottom: 17px;
          `}
          variant="contained"
          type="submit"
          disabled={finishDisabled || this.state.saving}
          // onClick={this.handleNext.bind(this)}
          onClick={this.createAccount.bind(this)}
        >
          { this.state.saving ? "Creating Account..." : 'Next' }
        </Button>
      </div>}
    >
      {/* Subtitle */}
      <div
        css={`
          font-family: Mulish;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: left;
          color: ${palette.secondary.dark};
          display: flex;
          margin-bottom: 32px;
        `}
      >
        Please fill out the fields below.
      </div>

      <div
        className='broker-fields'
        css={`
          display: flex;
          justify-content: center;
          margin-bottom: 30px;
          padding-bottom: 10px;
          border-bottom: 1px solid ${palette.secondary.sat30};
          @media (max-width: 850px) {
            flex-direction: column;
          }
        `}
      >
        <TextField
          label="Brokerage name"
          fullWidth
          onChange={this.handleChangeInputBusiness.bind(this)}
          name="brokerageName"
          value={this.props.user.businessInformation.brokerageName}
          autoFocus
        />
        <div css={`width: 20px;`} />
        <TextField
          label={
            <span
              css={`
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
              `}
            >
              Website 
              <span
                css={`
                  font-size: 10px;
                  font-weight: 200;
                  line-height: 11px;
                  letter-spacing: 0.04em;
                `}
              >
                optional
              </span>
            </span>
          }
          fullWidth
          onChange={this.handleChangeInputBusiness.bind(this)}
          name="website"
          value={this.props.user.businessInformation.website}
        />
      </div>

      <div
        css={`
          display: flex;
          justify-content: center;
          @media (max-width: 850px) {
            flex-direction: column;
          }
        `}
      >
        <TextField
          autoFocus={
            !this.props.user.account.firstName
          }
          label="First name"
          fullWidth
          onChange={this.handleChangeInput.bind(this)}
          name="firstName"
          value={this.props.user.account.firstName}
          disabled={this.state.saving}
        />
        <div css={`width: 20px;`} />
        <TextField
          autoFocus={
            this.props.user.account.firstName
            && !this.props.user.account.lastName
          }
          label="Last name"
          fullWidth
          onChange={this.handleChangeInput.bind(this)}
          name="lastName"
          value={this.props.user.account.lastName}
          disabled={this.state.saving}
        />
      </div>
      <div css={`height: 11px`}/>

      <div
        css={`
          display: flex;
          justify-content: center;
          @media (max-width: 850px) {
            flex-direction: column;
          }
        `}
      >
        <TextField
          autoFocus={
            this.props.user.account.firstName
            && this.props.user.account.lastName
            && !this.props.user.account.phoneNumber
          }
          label={
            <span
              css={`
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
              `}
            >
              Phone number 
              <span
                css={`
                  font-size: 10px;
                  font-weight: 200;
                  line-height: 11px;
                  letter-spacing: 0.04em;
                `}
              >
                optional
              </span>
            </span>
          }
          fullWidth
          onChange={this.handleChangeInput.bind(this)}
          name="phoneNumber"
          value={this.props.user.account.phoneNumber}
          disabled={this.state.saving}
        />
        <div css={`width: 20px;`} />
        <div css={`width: 100%;`} />
      </div>
      <div css={`height: 11px`}/>

      <div
        css={`
          display: flex;
          justify-content: center;
          @media (max-width: 850px) {
            flex-direction: column;
          }
        `}
      >
        <TextField
          autoFocus={
            this.props.user.account.firstName
            && this.props.user.account.lastName
            && this.props.user.account.phoneNumber
            && !this.props.password.password
          }
          label="Create password"
          fullWidth
          onChange={this.handleChangePassword.bind(this)}
          name="password"
          value={this.props.password.password}
          type="password"
          error={!this.props.password.passwordValid}
          helperText={this.props.password.passwordValidMessage}
          disabled={this.state.saving}
        />
        <div css={`width: 20px;`} />
        <TextField
          label="Verify password"
          fullWidth
          onChange={this.handleChangePassword.bind(this)}
          name="confirmPassword"
          value={this.props.password.confirmPassword}
          type="password"
          error={this.props.password.confirmPassword !== this.props.password.password}
          helperText={
            this.props.password.confirmPassword !== this.props.password.password
            && this.props.password.confirmPassword
              ? "Passwords don't match."
              : ''
          }
          disabled={this.state.saving}
        />
      </div>
      <div css={`height: 11px`}/>

      {/* Terms and Conditions Container */}
      <div
        css={`
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 25px;
        `}
      >
        <Checkbox
          checked={this.props.termsAndConditionsAgreed}
          onChange={this.handleChangeTermsAndConditions.bind(this)}
          cssProps={`
            margin-right: 17.45px;
          `}
        />

        <div
          css={`
            font-family: Mulish;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
            color: ${palette.secondary.dark};
          `}
        >
          By checking this box, I agree to these&nbsp;
            <a
              target="_blank"
              download="VerikaiTermsAndConditions.pdf"
              onClick={ fetchTandC }
              className='tAndC'
              css={`
                color: ${palette.secondary.dark};
                font-weight: 700;
                cursor:pointer; 
              `}
            >
              terms and conditions
            </a>.
        </div>
      </div>

      <div
        css={`
          font-family: Mulish;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
          color: ${palette.error.main};
          height: 16px;
          margin-bottom: 19px;
        `}
      >
        <AnimatePresence>
          {this.state.error && (
            <motion.div
              initial={{ opacity: 0, y: -5, }}
              animate={{ opacity: 1, y: 0, }}
              exit={{ opacity: 0, y: -5, }}
              transition={{
                type: "spring",
                damping: 10,
                stiffness: 100
              }}
            >
              {this.state.error}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

    </Display>;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSetup));
