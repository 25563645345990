import axios from 'axios';
import { Buffer } from 'buffer';

interface Params {
  assetEndpoint?: string;
  cacheKey?: string | null;
  route: string | null | undefined;
};

export const fetchImage = async({
  assetEndpoint="https://verikai-assets.s3.us-west-2.amazonaws.com",
  cacheKey=null,
  route=null,
}: Params) => {
  let response: string | undefined = undefined;

  if(route === null || route === undefined || route === '')
  {
    return response;
  }

  let endpoint = `${assetEndpoint}/${route}`;
  cacheKey = cacheKey === null
    ? endpoint
    : cacheKey;

  try {
    let image = await axios.get(
      `${assetEndpoint}/${route}`,
      {
        responseType: 'arraybuffer'
      }
    );
    let b64: string = Buffer.from(image.data, 'binary').toString('base64');
    let contentType: string = image.headers['content-type'];
    response = `data:${contentType};base64,${b64}`;
    localStorage.setItem(cacheKey, response);
    return response;
  }
  catch(err) {
    return response;
  }

};
