export default function formatPhoneNumber(v) {
  let number = v.replace(/\D/g, '').slice(0, 10);
  let formattedNumber = '';
  for (let i = 0; i < number.length; i++) {
    if (i === 3 || i === 6) {
      formattedNumber += '-'
    }
    formattedNumber += number[i];
  }

  return formattedNumber;
}
