// Setup.1. Core
import 'styled-components/macro';
import { palette } from '../theme';


interface TableProps {
    headerItems: Array<string | JSX.Element>;
    headerKeys: Array<string>;
    columnProps: Array<{width: string, "text-align": string, weight: number, color?: string, "font-size"?: string}>;
    rows: Array<any>;
  }

// Root
export const Table = (props: TableProps) => {
    return <div>
            {/* header */}
            <div
                css={`
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    padding: 15px 10px;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 800;
                    padding-bottom: 16px;
                    border-bottom: 1px solid ${palette.secondary.sat};
                    color: ${palette.secondary.dark};
                    @media (max-width: 850px) {
                        max-width: 400px;
                    }
                `}
            >
                {props.headerItems.map((item, i) => {
                    return <div 
                        css={`
                            flex: 1 1 ${props.columnProps[i].width};
                            width: ${props.columnProps[i].width};
                            margin-right: 15px;

                            @media (max-width: 850px) {
                                max-width: 150px;
                            }
                        `}
                    >
                        {item}
                    </div>
                })}
            </div>
            {/* Row */}
            {props.rows.map(row => {
                return <div
                    className='table-row'
                    css={`
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        padding: 15px 10px;
                        font-size: 14px;
                        line-height: 16.1px;
                        color: ${palette.secondary.dark};
                        background: rgba(255, 255, 255, 0.5);
                        &:nth-child(even) {
                            background: rgba(224, 223, 236, 0.5);
                        }
                        @media (max-width: 850px) {
                            max-width: 400px;
                        }
                    `}
                >
                    {/* Cell */}
                    {props.headerKeys.map((item, i) => {
                        return <div
                            css={`
                                flex: 1 1 ${props.columnProps[i].width};
                                width: ${props.columnProps[i].width};
                                text-align: ${props.columnProps[i]['text-align']};
                                margin-right: 15px;
                                font-weight: ${props.columnProps[i].weight};
                                color: ${props.columnProps[i].color ? props.columnProps[i].color : "inherit"};
                                font-size: ${props.columnProps[i]["font-size"] ? props.columnProps[i]["font-size"] : "14px"};

                                @media (max-width: 850px) {
                                    max-width: 150px;
                                }
                            `}
                        >
                            {row[item]}
                        </div>
                    })}
                </div>
            })}
        </div>
  }
  
  Table.defaultProps = {
  }