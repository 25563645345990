import React from 'react';
import 'styled-components/macro';
import clsx from 'clsx';
import {
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

import { palette } from '../../theme';

import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputAdornment from '@material-ui/core/InputAdornment';
import Spacer from './Spacer';
import TextField from './TextField';
import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/styles/withStyles';

const styles = (theme) => ({
  chooseOnePrimaryButton: {
    borderRadius: '10px',
    boxShadow: 'none',
    height: '76px',
    justifyContent: 'left',
    padding: '30px 20px 30px 40px',
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  chooseOnePrimaryButtonLabelContainer: {
    display: 'flex',
    width: '100%',
    textAlign: 'left',
  },
  chooseOnePrimaryButtonNotSelected: {
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0em',
    color: theme.palette.secondary.sat50,
    backgroundColor: theme.palette.secondary.sat30_45,
    border: `1px solid ${theme.palette.secondary.sat50}`,
    '&:hover': {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.secondary.sat50,
    },
  },
  chooseOnePrimaryButtonSelected: {
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0em',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main25,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main25,
    },
    border: `1px solid ${theme.palette.primary.main}`,
  },
  chooseOnePrimaryContainer2: {
    display: 'grid',
    columnGap: '20px',
    gridTemplateColumns: '1fr 1fr',
  },
  chooseOnePrimaryContainer3: {
    display: 'grid',
    columnGap: '30px',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  chooseOneSecondaryButton: {
    borderRadius: '10px',
    boxShadow: 'none',
    height: '57px',
    justifyContent: 'left',
    padding: '30px 20px 30px 40px',
    textTransform: 'none',
    width: '261px',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  chooseOneSecondaryChoiceContainer: {
    display: 'flex',
  },
  chooseOneSecondaryChoiceSpacer: {
    width: '20px',
  },
  chooseOneSecondaryContainer: {
    padding: '30px 30px 30px 20px',
    backgroundColor: theme.palette.secondary.light25,
    border: `1px solid ${theme.palette.secondary.sat17}`,
    borderRadius: '6px',
  },
  chooseOneTypography: {
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0em',
    color: theme.palette.secondary.sat50,
  },
  optionalContainer: {
    flex: '1',
    marginRight: '27px',
  },
  optionalTypography: {
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '21px',
    letterSpacing: '0em',
    color: theme.palette.secondary.sat50,
    textAlign: 'right',
  },
  primaryContainer: {
    paddingLeft: '25px',
    paddingRight: '26px',
  },
  primaryQuestionTypography: {
    fontFamily: 'Mulish',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '27px',
    letterSpacing: '0em',
    color: theme.palette.secondary.dark,
    textAlign: 'left',
  },
  secondaryEnterAnswerQuestionTypography: {
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '21px',
    letterSpacing: '0em',
    color: theme.palette.secondary.dark,
  },
  selectedIconContainer: {
    flex: '1',
    textAlign: 'right',
    fontSize: '24px',
  },
  titleContainer: {
    display: 'flex',
  },
  titleTypography: {
    fontFamily: 'Mulish',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '36px',
    letterSpacing: '0em',
    color: theme.palette.secondary.dark,
  },
});

class QuestionCard extends React.Component {

  render() {
    const { classes } = this.props;

    //TODO: Add a top container and use padding to omit the error I'm getting
    //from the marginTop issues w/Typography's class.
    return <div
      css={`
        font-size: 30px;
        width: 100%;
        padding-top: 20px;
        padding-left: 39px;
        padding-bottom: 30px;
        padding-right: 20px;
        background-color: ${palette.secondary.light25};
        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(125, 122, 161, 0.25);
      `}
    >
      {
        this.props.title
          ? <div
            className={classes.titleContainer}
          >
            <Typography
              variant="h2"
              component="h2"
              className={classes.titleTypography}
            >
              {this.props.title}
            </Typography>
            {
              this.props.optional
                ? <div
                  className={classes.optionalContainer}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.optionalTypography}
                  >
                    Optional
                  </Typography>
                </div>
                : null
            }
          </div>
          : null
      }

      <div
        className={classes.primaryContainer}
      >

        {
          this.props.chooseOnePrimary
            ? <ChooseOne
              choices={this.props.chooseOnePrimary}
              question={this.props.chooseOnePrimaryQuestion}
              name={this.props.chooseOnePrimaryName}
              onChange={this.props.onChange}
              value={this.props.chooseOnePrimaryValue}
            />
          : this.props.primaryEnterAnswerName
            ? <>
              <Spacer pixels={30} />
              <Typography
                variant="body1"
                component="p"
                className={classes.secondaryEnterAnswerQuestionTypography}
              >
                Please enter answer
            </Typography>

              <Spacer pixels={10} />

              <TextField
                fullWidth
                startAdornment={
                  this.props.primaryQuestionStartInputAdornment
                    ? <InputAdornment
                      position="start"
                    >
                      {this.props.primaryQuestionStartInputAdornment}
                    </InputAdornment>
                    : undefined
                }
                endAdornment={
                  this.props.primaryQuestionEndInputAdornment
                    ? <InputAdornment
                      position="start"
                    >
                      {this.props.primaryQuestionEndInputAdornment}
                    </InputAdornment>
                    : undefined
                }
                onChange={(e) => this.props.onChange(this.props.primaryEnterAnswerName, e.target.value, "enter", e)}
                value={this.props.primaryEnterAnswerValue}
                variant="white"
              />

              {this.props.submitButton}
            </>
            : null
        }

        {
          this.props.chooseOneSecondary
            ? <>
              <Spacer pixels={30} />
              <div
                className={classes.chooseOneSecondaryContainer}
              >
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.primaryQuestionTypography}
                >
                  {this.props.chooseOneSecondaryQuestion}
                </Typography>

                <Spacer pixels={16} />

                <Typography
                  variant="body1"
                  component="p"
                  className={classes.chooseOneTypography}
                >
                  Choose one
                </Typography>

                <Spacer pixels={10} />

                <div
                  className={classes.chooseOneSecondaryChoiceContainer}
                >
                  {
                    this.props.chooseOneSecondary.map((row, idx) => <>
                      <Button
                        key={`questioncardbutton${idx}bar`}
                        className={clsx(
                          classes.chooseOneSecondaryButton,
                          this.props.chooseOneSecondaryValue === row.name
                            ? classes.chooseOnePrimaryButtonSelected
                            : classes.chooseOnePrimaryButtonNotSelected
                        )}
                        color={
                          this.props.chooseOneSecondaryValue === row.name
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={() => this.props.onChange(this.props.chooseOneSecondaryName, row.name)}
                        variant="contained"
                      >
                        <div
                          className={classes.chooseOnePrimaryButtonLabelContainer}
                        >
                          <div>
                            <Typography
                              variant="body1"
                              component="p"
                              className={classes.chooseOnePrimaryButtonLabelTypography}
                            >
                              {row.name}
                            </Typography>
                          </div>
                          {
                            this.props.chooseOneSecondaryValue === row.name
                              ? <div
                                className={classes.selectedIconContainer}
                              >
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                />
                              </div>
                              : null
                          }
                        </div>
                      </Button>
                      {
                        idx !== this.props.chooseOneSecondary.length - 1
                          ? <div
                            className={classes.chooseOneSecondaryChoiceSpacer}
                          />
                          : null
                      }
                    </>)
                  }
                </div>
              </div>
            </>
            : this.props.secondaryEnterAnswerQuestion
              ? <>
                <Spacer pixels={20} />
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.secondaryEnterAnswerQuestionTypography}
                >
                  {this.props.secondaryEnterAnswerQuestion}
                </Typography>
                <Spacer pixels={10} />
                <TextField
                  fullWidth
                  onChange={(e) => this.props.onChange(this.props.secondaryEnterAnswerName, e.target.value, "enter", e)}
                  value={this.props.secondaryEnterAnswerValue}
                  variant="white"
                />
              </>
              : null
        }


      </div>
    </div>;
  }
}

export default withStyles(styles)(QuestionCard);

class ChooseOnePrimaryNoStyles extends React.Component {

  render()
  {
    const { classes } = this.props;

    return <>
      {/* Question Title */}
      <div
        css={`
          font-family: Mulish;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: ${palette.secondary.dark};
        `}
      >
        {this.props.question}
      </div>

      {
        this.props.choices
          ? <>
            <div
              css={`
                font-family: Mulish;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 18px;
                letter-spacing: 0em;
                color: ${palette.secondary.sat50};
                margin-top: 13px;
                margin-bottom: 10px;
              `}
            >
              Choose one
            </div>

            <div
              css={`
                display: grid;
                column-gap: 20px;
                grid-template-columns: ${new Array(this.props.choices.length).fill('1fr').join(' ')
                };

                display: 'grid',
                columnGap: '20px',
                gridTemplateColumns: '1fr 1fr',
              `}
            >
              {
                this.props.choices.map((row, idx) =>
                  <Button
                    key={`questioncardbutton${idx}foo`}
                    className={clsx(
                      classes.chooseOnePrimaryButton,
                      this.props.value === row.name
                        ? classes.chooseOnePrimaryButtonSelected
                        : classes.chooseOnePrimaryButtonNotSelected
                    )}
                    color={
                      this.props.value === row.name
                        ? 'primary'
                        : 'secondary'
                    }
                    onClick={() => this.props.onChange(this.props.name, row.name)}
                    variant="contained"
                  >
                    <div
                      className={classes.chooseOnePrimaryButtonLabelContainer}
                    >
                      <div>
                        <Typography
                          variant="body1"
                          component="p"
                          className={classes.chooseOnePrimaryButtonLabelTypography}
                        >
                          {row.name}
                        </Typography>
                      </div>
                      {
                        this.props.value === row.name
                          ? <div
                            className={classes.selectedIconContainer}
                          >
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                            />
                          </div>
                          : null
                      }
                    </div>
                  </Button>
                )
              }
            </div>
          </>
          : null
      }
    </>;
  }
}

const ChooseOne = withStyles(styles)(ChooseOnePrimaryNoStyles);

export {
  ChooseOne
}
