// Setup-1. Import React/Redux
import React from 'react';
import 'styled-components/macro';
// Setup-2. Import Libs
import { palette } from '../theme';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";

// Setup-3. Import Components
import { Typography } from './Typography';
import { keyCheck } from "../lib/keyCheck";

// Setup-4. Props Interface
interface Props {
  /**
   * the children of the component
   **/
  children: React.ReactNode;
  /** indicates disabled state */
  disabled: boolean;
  /** the font color when disabled state is triggered. Defaults to {@link palette.gray.main5}. */
  disabledColor: string;
  /** the element ID */
  id?: string;
  /** indicates whether or not the `src` is a path local to the app */
  local?: boolean | null | undefined;
  /** the event handler that is triggered when a click occurs */
  onClick?: React.MouseEventHandler;
  /** indicates whether or not the link opens a new tab */
  newTab?: boolean | null | undefined;
  /** the path of the source destination in the link */
  src?: string;
  /** the {@link TypographyComponentProps | props} passed to {@link Typography | <Typography/>} */
}

/**
 * Link
 * @component
 * @example
 * <Link
 *   disabled={state.loading}
 *   id="submit-report-without-business-info"
 *   typographyProps={{
 *     color: palette.primary.main,
 *     lineHeight: '11.5px',
 *     size: '10px',
 *     textTransform: 'uppercase',
 *     weight: 800,
 *   }}
 * >
 *   Submit report with your business info
 * </Link>
 */
export const Link = (props: Props) => {

  // 1. History for local calls
  let history:any = useHistory();

  // 1. Src vs. Click
  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    if(props.disabled) {
      return;
    }
    else if(props.onClick) {
      props.onClick(e);
    }
    else if(props.src) {
      if(props.local) {
        history.push(props.src);
      }
      else {
        let src:string = /^http:\/\/|^https:\/\//g.test(props.src)
        ? props.src
        : `http://${props.src}`;

        if(props.newTab) {
          window.open(src, '_blank');
        }
        else {
          window.location.assign(src);
        }
      }
    }
  }

  // 999. Render
  return <Button
    disabled={props.disabled}
    id={props.id}
    onClick={handleClick}
  >
    <Typography
      color={
        props.disabled
          ? props.disabledColor
          : "black"
      }
    >
      <div
        css={`
          display: flex;
          align-items: center;
        `}
      >
        {props.children}
      </div>
    </Typography>
  </Button>
}

Link.defaultProps = {
  disabled: false,
  disabledColor: palette.gray.main5,
};

// SC-1. Button
const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: ${
    p => p.disabled
      ? 'default'
      : 'pointer'
  };
  padding: 0;
`;