export const brokerFormSteps = [
  // {
  //   label: 'Business information',
  //   route: '/business-information',
  // },
  // {
  //   label: 'Tier plan',
  //   route: '/tier-plan',
  // },
  {
    label: 'Account setup',
    route: '/account-setup',
    isWhiteLabel: true
  },

  {
    label: 'Communication',
    route: '/communication',
  },

  {
    label: 'Team Invite',
    route: '/team-invite',
    isWhiteLabel: true
  },
];

export default brokerFormSteps;
