// This utility Transforms
// JSS 👉 CSS

/*
Example:

{
  fontSize: '2rem',
  fontWeight: 'bold',
}

👇

`font-size: 2rem; font-weight: bold;`

*/

const postcss = require('postcss');
const postcssJs = require('postcss-js');

// Default export
const convertJSSToCSS = (jss) => {
  // postcss won't put a semicolon
  // on the last CSS rule (weird...)
  const css = postcss().process(jss, { parser: postcssJs }).css + ';'
  return css
}

// Helper function
const iterateJSStoCSS = (jssDictionary) => {

  const cssDictionary = {}

  for (const key in jssDictionary) {
    if (Object.hasOwnProperty.call(jssDictionary, key)) {
      const jss = jssDictionary[key];
      cssDictionary[key] = convertJSSToCSS(jss);
    }
  }

  return cssDictionary;
}

export default convertJSSToCSS;

export {
  iterateJSStoCSS,
}