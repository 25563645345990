import {
  SET_PREPOPULATE,
} from "../actionTypes";

export default function setPrepopulate(v) {
  return {
    type: SET_PREPOPULATE,
    payload: v,
  };
}
