import React from 'react';
import 'styled-components/macro';

import Autocomplete from './verikai/Autocomplete';
import Button from '@material-ui/core/Button';
import { ChooseOne } from './verikai/QuestionCard';
import Display, { DisplayContainer } from './Display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Steps from './Steps';
import TextField, {Label} from './verikai/TextFieldBase';

import { connect } from 'react-redux';
import brokerFormSteps from '../lib/brokerFormSteps';
import {
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { palette } from '../theme';
import prettyInt from '../lib/prettyInt';
import setUser from '../redux/actions/setUser';
import underwriterFormSteps from '../lib/underwriterFormSteps';
import { withRouter } from "react-router-dom";
import resetUser from '../redux/actions/resetUser';

const mapDispatchToProps = dispatch => ({
  resetUser: v => dispatch(resetUser()),
  setUser: v => dispatch(setUser(v)),
});

const mapStateToProps = state => ({
  user: state.user,
  prepopulate: state.prepopulate,
});

class BusinessInformation extends React.Component {

  componentDidMount()
  {
    if(!this.props.user.email || !this.props.user.emailVerified)
    {
      this.props.history.push('/create-an-account');
    }
    else if(!this.props.user.accountType)
    {
      this.props.history.push('/account-type');
    }
  }

  handleChangeInput(e)
  {
    let value = e.target.value;
    if(e.target.name === 'stoplossGroupsPerYear')
    {
      value = prettyInt(value, true, this.props.user.businessInformation.stoplossGroupsPerYear);
    }
    this.props.setUser({
      businessInformation: {
        ...this.props.user.businessInformation,
        [e.target.name]: value,
      },
    });
  }

  handleChangeState(e, v, r)
  {
    if(v[v.length - 1] === 'All' || v.length >= 50)
    {
      this.props.setUser({
        businessInformation: {
          ...this.props.user.businessInformation,
          states: ['All'],
        },
      })
    }
    else if(v[0] === 'All' && v.length > 1)
    {
      this.props.setUser({
        businessInformation: {
          ...this.props.user.businessInformation,
          states: v.slice(1),
        },
      });
    }
    else
    {
      this.props.setUser({
        businessInformation: {
          ...this.props.user.businessInformation,
          states: v,
        },
      });
    }
  }

  handleBack()
  {
    this.props.history.push('/account-type');
  }

  handleNext()
  {
    if(this.props.user.accountType === 'Broker')
    {
      this.props.history.push('/account-setup');
    }
    else
    {
      this.props.history.push('/person-to-contact');
    }
  }

  render()
  {
    const b = this.props.user.businessInformation;
    const nextDisabled = this.props.user.accountType === 'Broker'
      ? (
        !b.brokerageName
      )
      : (
        !b.businessName
        || !b.joinMarketplace
      );

    return <Display
      title={`Tell us about your ${this.props.user.accountType === 'Broker' ? 'brokerage' : 'business'}`}
      outerTop={<Steps
        currentStepNumber={1}
        steps={
          this.props.user.accountType === 'Broker'
            ? brokerFormSteps
            : underwriterFormSteps
        }
      />}
      outerBottom={<>
        {
          this.props.user.accountType !== 'Broker'
            ? <>
              <div
                css={`
                  height: 38px;
                `}
              />
              <DisplayContainer>
                <div
                  css={`
                    font-family: Mulish;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 23px;
                    letter-spacing: 0em;
                    color: ${palette.secondary.dark};
                    margin-bottom: 32px;
                  `}
                >
                  Are you interested in joining the marketplace?
                </div>
                <ChooseOne
                  choices={[
                    {
                      name: "Yes, I'm interested!",
                    },
                    {
                      name: "No, I'm not interested",
                    },
                  ]}
                  name="joinMarketplace"
                  onChange={(n, v) => this.handleChangeInput({target: {value:v,name:n}})}
                  value={this.props.user.businessInformation.joinMarketplace}
                />
              </DisplayContainer>
            </>
            : null
        }
        <div
          css={`
            display: flex;
            justify-content: space-between;
            margin-top: 32px;
            @media (max-width: 850px) {
              flex-direction: column-reverse;
            }
          `}
        >
          {
            this.props.prepopulate
              ? <div></div>
              : <Button
              css={`
                color: ${palette.link.blue};
                text-transform: none;
                font-family: Mulish;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: 17px;
                letter-spacing: 0em;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 10px;
                padding-bottom: 10px;
                border-radius: 5px;
              `}
              onClick={this.handleBack.bind(this)}
            >
              Back
            </Button>
          }
          

          <Button
            css={`
              background-color: ${palette.primary.main};
              box-shadow: none;
              margin-top: 7px;
              text-transform: none;
              font-family: Mulish;
              font-size: 15px;
              font-style: normal;
              font-weight: 800;
              line-height: 17px;
              letter-spacing: 0em;
              color: #fff;
              padding-top: 14.5px;
              padding-bottom: 14.5px;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                box-shadow: none;
                background-color: ${palette.primary.main};
              }
              margin-bottom: 17px;
            `}
            variant="contained"
            type="submit"
            disabled={nextDisabled}
            onClick={this.handleNext.bind(this)}
          >
            <div>
              Next
            </div>
            <FontAwesomeIcon
              css={`
                margin-left: 10px;
                font-size: 12px;
                font-weight: 900;
              `}
              icon={faArrowRight}
            />
          </Button>
        </div>
      </>}
    >

      {/* Subtitle */}
      <div
        css={`
          font-family: Mulish;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: left;
          color: ${palette.secondary.dark};
          display: flex;
          margin-bottom: 32px;
        `}
      >
        Please fill out the fields below.
      </div>

      {/* Brokerage or Underwiter */}
      {
        this.props.user.accountType === 'Broker'
          ? <>
            <TextField
              label="Brokerage name"
              fullWidth
              onChange={this.handleChangeInput.bind(this)}
              name="brokerageName"
              value={this.props.user.businessInformation.brokerageName}
              autoFocus
            />
            <div css={`height: 11px`}/>

            <div
              css={`
                display: flex;
                justify-content: center;
              `}
            >
              <TextField
                label="Headquarters location"
                fullWidth
                onChange={this.handleChangeInput.bind(this)}
                name="headquartersLocation"
                value={this.props.user.businessInformation.headquartersLocation}
              />
              <div css={`width: 20px;`} />
              <TextField
                label="Website"
                fullWidth
                onChange={this.handleChangeInput.bind(this)}
                name="website"
                value={this.props.user.businessInformation.website}
              />
            </div>

            <div css={`height: 11px`}/>

            <TextField
              label="How many groups does your business place with stop loss products per year? "
              fullWidth
              onChange={this.handleChangeInput.bind(this)}
              name="stoplossGroupsPerYear"
              value={this.props.user.businessInformation.stoplossGroupsPerYear}
            >
              hi
            </TextField>
            <div css={`height: 11px`}/>

            <Label>
              In which states does your business place groups?&nbsp;
              <span
                css={`
                  font-family: Mulish;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 11px;
                  letter-spacing: 0.04em;
                  text-align: left;
                `}
              >
                (multiple select)
              </span>
            </Label>
            <Autocomplete
              onChange={this.handleChangeState.bind(this)}
              options={[
                'All',
                'Alabama',
                'Alaska',
                'Arizona',
                'Arkansas',
                'California',
                'Colorado',
                'Connecticut',
                'Delaware',
                'Florida',
                'Georgia',
                'Hawaii',
                'Idaho',
                'Illinois',
                'Indiana',
                'Iowa',
                'Kansas',
                'Kentucky',
                'Louisiana',
                'Maine',
                'Montana',
                'Nebraska',
                'Nevada',
                'New Hampshire',
                'New Jersey',
                'New Mexico',
                'New York',
                'North Carolina',
                'North Dakota',
                'Ohio',
                'Oklahoma',
                'Oregon',
                'Maryland',
                'Massachusetts',
                'Michigan',
                'Minnesota',
                'Mississippi',
                'Missouri',
                'Pennsylvania',
                'Rhode Island',
                'South Carolina',
                'South Dakota',
                'Tennessee',
                'Texas',
                'Utah',
                'Vermont',
                'Virginia',
                'Washington',
                'West Virginia',
                'Wisconsin',
                'Wyoming',
              ]}
              value={this.props.user.businessInformation.states}
            />

          </>
          : <>
            <TextField
              label="Business name"
              fullWidth
              onChange={this.handleChangeInput.bind(this)}
              name="businessName"
              value={this.props.user.businessInformation.businessName}
              autoFocus
            />
            <div css={`height: 11px`}/>

            <div
              css={`
                display: flex;
                justify-content: center;
              `}
            >
              <TextField
                label="Headquarters location"
                fullWidth
                onChange={this.handleChangeInput.bind(this)}
                name="headquartersLocation"
                value={this.props.user.businessInformation.headquartersLocation}
              />
              <div css={`width: 20px;`} />
              <TextField
                label="Website"
                fullWidth
                onChange={this.handleChangeInput.bind(this)}
                name="website"
                value={this.props.user.businessInformation.website}
              />
            </div>
          </>
      }

    </Display>;
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessInformation));
